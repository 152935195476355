import axios from 'axios';

const service = axios.create({
  baseURL: "https://pacifichunter-winghaus-cnc-admin.2dm.digital/api",
  timeout: 30 * 1000, //300000
})

service.interceptors.request.use(config => {
  const token = process.env.REACT_APP_TOKEN;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}, error => {
  console.log({ error })
  Promise.reject(error)
})

export default service;