/* This component is to render the in-game
interface that sits at the top of the screen
this will show the SCORE, LIVES and TIME. */
import cncLogo from '../img/cnc_logo_md.png';

export function GameScoreElement({ score, distance, lives }) {

    const livesArray = Array.from({ length: lives});

    function gameUIElement(data, elemLabel) {
        return(<>
        <div className=" flex flex-col items-center">
            <div className="w-[105px] text-center font-jock">
                <h3 className="absolute w-[104px] pt-0 text-white">{ elemLabel }</h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="105" height="28" viewBox="0 0 105 28" fill="none">
                    <path d="M11.7862 0H104.8023L93.0161 28H0L11.7862 0Z" fill="#CE2927"/>
                </svg>
            </div>
            <h1 className="text-center text-black">
                { data }
            </h1>
        </div>
        </>)
    }

    return(
        <div className="w-full px-4 flex justify-center mt-6 absolute top-0 z-[888]">
            {/* SCORE */}
                { gameUIElement(score, "SCORE") }
            {/* LIVES + LOCKUP */}
                <div className="mx-4 items-center flex flex-col sm:w-1/4 lg:w-1/6">
                    <img src={cncLogo} className='w-24 mt-6' />
                    <div id="lives" className='flex space-x-2 mt-3'>
                        {  livesArray.map((_, index)=>{
                                return(
                                    <h3 key={index}>🏉</h3>
                                )
                            })
                        }
                    </div>
                </div>
            {/* TIME */}
                { gameUIElement(distance, "METRES") }
        </div>
    )
}