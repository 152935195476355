import { useGLTF } from "@react-three/drei";

const glb = "models/Rugby.glb";
export function Rugby(props) {
  const { nodes, materials } = useGLTF(glb);
  return (
    <group ref={props.ballRef} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["10525_Rugby_Ball_v1"].geometry}
        material={materials.RugbyBall}
        position={[0.011, -0.03, 0.255]}
        rotation={[-1.571, -0.542, -Math.PI / 2]}
        scale={0.065}
      />
    </group>
  );
}

useGLTF.preload(glb);