/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useMemo } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { LoopRepeat, MeshToonMaterial } from 'three'
import { useFrame, useGraph } from '@react-three/fiber'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js';

const glb = "models/Defender.glb";
export function Defender(props) {
  const group = useRef();
  const { scene, materials, animations } = useGLTF(glb);
  const { actions, mixer } = useAnimations(animations, group);

  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene])
  const { nodes } = useGraph(clone)
  
  const red = useTexture("textures/jerseys/winghaus_jersey_v1.jpg")
  red.flipY = false

  useEffect(() => {
    if (nodes["10525_Rugby_Ball_v1"]) {
      nodes["10525_Rugby_Ball_v1"].visible = false
    }
  }, [nodes])

  useEffect(() => {
    if (actions.SprintNoBall && !props.isGameOver) {
      actions.SprintNoBall.setLoop(LoopRepeat, Infinity)
      actions.SprintNoBall.clampWhenFinished = true
      actions.SprintNoBall.setDuration(1.5)
      actions.SprintNoBall.reset().startAt(Math.random() > 0.5 ? 1 : 0).play()
    }
  }, [actions])

  const stopAllAnim = () => {
    Object.keys(actions).forEach((key) => {
      actions[key].stop().reset()
    })
  }

  useFrame((state, delta) => {
    if (mixer) {
      mixer.update(delta)
    }
  })

  useEffect(() => {
    if (props.isGameOver) {
      stopAllAnim()
    } else if (actions.SprintNoBall) {
      actions.SprintNoBall.setLoop(LoopRepeat, Infinity)
      actions.SprintNoBall.clampWhenFinished = true
      actions.SprintNoBall.setDuration(1.5)
      actions.SprintNoBall.reset().play()
    }
  }, [props.isGameOver])

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig_jago" position={[0, 2.427, 0.083]}>
          <group name="Chicken">
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago"
              geometry={nodes.jago.geometry}
              material={new MeshToonMaterial({ color: "#ff0000" })}
              skeleton={nodes.jago.skeleton}
            />
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago_1"
              geometry={nodes.jago_1.geometry}
              material={new MeshToonMaterial({ map: red })}
              skeleton={nodes.jago_1.skeleton}
            />
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago_2"
              geometry={nodes.jago_2.geometry}
              material={new MeshToonMaterial({ color: "black" })}
              skeleton={nodes.jago_2.skeleton}
            />
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago_3"
              geometry={nodes.jago_3.geometry}
              material={new MeshToonMaterial({ map: materials.BaseTexture.map })}
              skeleton={nodes.jago_3.skeleton}
            />
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago_4"
              geometry={nodes.jago_4.geometry}
              material={new MeshToonMaterial({ color: "brown" })}
              skeleton={nodes.jago_4.skeleton}
            />
          </group>
          <primitive object={nodes.root} castShadow receiveShadow />
          <primitive object={nodes.pinggang} castShadow receiveShadow />
          <primitive object={nodes["ik-kakiL"]} castShadow receiveShadow />
          <primitive object={nodes["ik-kakiR"]} castShadow receiveShadow />
        </group>
        <group name="Empty" position={[4, 0, 2]}>
          <group name="giz_jari" />
          <group name="giz_kaki" />
          <group name="giz_main" />
          <group name="giz_root" scale={0.553} />
          <group name="giz_tangan" />
          <group name="giz_tulang" />
          <mesh
            name="giz_tumit"
            castShadow
            receiveShadow
            geometry={nodes.giz_tumit.geometry}
            material={nodes.giz_tumit.material}
          />
          <group name="giz_wajah" />
        </group>
        <group name="WorldOrigin" />
      </group>
    </group>
  );
}

useGLTF.preload(glb);