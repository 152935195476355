import service from "./service";

export async function checkDuplicateTeamName(name, email) {
  try {
    const res = await service.get(`/players?filters[teamName]=${name}`);
    if (res.data.meta.pagination.total > 0 && !res.data.data.find(o => o.attributes.email === email)) {
      return true
    } else {
      return false
    }
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}

export async function checkExistingEmail(email) {
  try {
    const res = await service.get(`/players?filters[email]=${email}`);
    if (res.data.meta.pagination.total > 0) {
      return res.data.data[0]
    } else return false
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}

export async function profanityCheck(text) {
  const res = await service.post('/players/check-name', {
    data: {text}
  })
  return res.data
}

export async function createPlayer(data) {
  try {
    const res = await service.post('/players', {
      data: data
    });
    return res.data
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}