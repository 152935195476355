import { useEffect, useState } from "react"

export function Counter({countDown, handleComplete}) {
  const [count, setCount] = useState(countDown);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 1) {
        setCount(count - 1);
      } else {
        clearInterval(interval);
        handleComplete();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [count]);

  return (
    <div className="absolute top-0 left-0 z-[10] w-full h-[100dvh] flex justify-center items-center">
      <div className="text-6xl font-jock text-white">{count}</div>
    </div>
  )
}