import { useState } from 'react';
import CustomInput from './input'
import winghausLogoTransparent from '../img/winghaus-watermark.svg';
import gameLogoImg from '../img/cnc_logo_md.png'
import CloseButton from './button_close'
import { motion } from "framer-motion";
import { PrimaryButton } from './buttons'
import { useForm } from 'react-hook-form'
import { sendInvite } from '../api/invite';
import useDetectDVH from '../hooks/useDetectDVH';

function Modal({name, onClose, returnToLeaderboard}) {
  return <div className="absolute inset-x-0 inset-y-0 bg-black bg-opacity-80 backdrop-blur-[7.5px] z-[1000] flex flex-col justify-center items-center">
    <div 
      className="relative w-[88.94vw] h-[115.68vw] pt-[7vw] pb-[24vw] px-4 rounded inviteModalBg"
    >
      <div className="flex justify-end ">
        <CloseButton onClick={onClose}/>
      </div>
      <div className='my-[20vw] text-white text-center text-[5.141vw] mx-auto w-[93%] tracking-[0.6px] leading-none font-jock'>We've sent out the challenge to { name }!</div>
      <div className="mx-auto">
        <PrimaryButton 
          size="long" 
          type="primary" 
          className="!w-[264.33px]"
          clickEvent={returnToLeaderboard}
        >LEADERBOARD</PrimaryButton>
      </div>
    </div>
  </div>
}

export default function Invite({ inviterId, returnToLeaderboard }) {
  const [showModal, setShowModal] = useState(false)
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    mode: "blur",
  })
  const formValues = watch()
  const onSubmit = async (values) => {
    await sendInvite({name: formValues.name, email: formValues.email, inviterId: { connect: [ inviterId ]}})
    setShowModal(true)
  }

  useDetectDVH()

  return (<>
    <div className='grassBg absolute top-0 left-0 z-[999] w-full h-[100dvh] flex flex-col items-center justify-between px-4 py-6 overflow-scroll'>
      {/* Title */}
      <h2 className="font-jock text-white tracking-[0.9px] text-3xl">INVITE FRIENDS</h2>
      {/* Logo */}
      <motion.img
          initial={{scale: 0.7, opacity: 0}}
          animate={{scale: 1, opacity: 1, transition: {duration: 1, delay: 0}}}
          src={gameLogoImg}
          className='mx-auto mt-[2vw] w-[88.7vw]'
      />
      {/* Form area */}
      <form className="w-full">
        <CustomInput 
          className="mt-[7vw]"
          placeholder='Name' 
          name="name" 
          value={formValues.name}
          error={errors?.name}
          register={register("name", { required: {value: true, message: 'Please enter your name'} })}
          onClear={() => setValue('name', '')}
        />
        <CustomInput 
          className="mt-[10vw]"
          placeholder='Email' 
          name="email" 
          value={formValues.email}
          error={errors?.email}
          register={register("email", { 
            required: {value: true, message: 'Please enter a email'},
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Please enter a valid email address",
            }
          })}
          onClear={() => setValue('email', '')}
        />
      </form>
      {/* FOOTER */}
      <div className='w-full flex items-center place-content-between mt-[10vw]'>
        <img alt="img" src={winghausLogoTransparent} className='w-14' />
        <PrimaryButton 
          size="small" 
          type="primary" 
          className="!w-[174.95px] h-[64px]"
          clickEvent={handleSubmit(onSubmit)}
        >SUBMIT</PrimaryButton>
      </div>
    </div>
    {
      showModal && (
        <Modal 
          name={formValues.name}
          returnToLeaderboard={returnToLeaderboard}
          onClose={returnToLeaderboard}
        />
      )
    }
  </>)
}