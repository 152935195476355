import { useEffect, useState } from 'react'
import logoImg from '../img/winghaus-logo.svg'
import demo1Img from '../img/back_demo_1.png'
import demo2Img from '../img/back_demo_2.png'
import { PrimaryButton } from './buttons'
import useDetectDVH from '../hooks/useDetectDVH'

export default function Back() {

  const [count, setCount] = useState(0)

  useDetectDVH()

  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 1) {
        setCount(count - 1);
      } else {
        clearInterval(interval);
        window.location.reload();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [count])

  useEffect(() => {
    setCount(3)
  }, [])

  return <div className="grassBg absolute inset-x-0 inset-y-0 h-[100dvh] backdrop-blur-[7.5px] z-[1000] py-6 overflow-scroll">
    <div className='h-[180.34vw] backBg w-[89.717vw] pt-[16vw] font-jock relative mx-auto'>
      <img src={logoImg} className="w-[20vw] absolute -top-[2vw] -translate-y-[2vw] left-1/2 transform -translate-x-1/2" />
      <h2 className="text-center text-[7.71vw] tracking-[0.9px] leading-[7.3vw] pt-4 text-white w-[75%] mx-auto">BEFORE YOU GO<br /> SEE WHAT'S ON</h2>
      <img src={demo1Img} className='w-[58.74vw] mx-auto mt-[5vw]' />
      <img src={demo2Img} className='w-[58.74vw] mx-auto mt-[7vw]' />
    </div>
    <div className="mx-auto">
        <PrimaryButton 
          size="long" 
          type="primary" 
          className="!w-[264.33px] mt-[8vw]"
          clickEvent={() => window.location.reload()}
        >HOME</PrimaryButton>
      </div>
  </div>
}