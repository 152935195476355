/* 
 * Splash screen: function is simply to direct players to the sign up form
 * Note: Background image is placeholder as the intention is the interface will animate, while the background remains static between 'pages'
 */
import { useEffect, useState } from 'react';
import { PrimaryButton } from './buttons';
import TermsModal from './terms_page';
import useDetectDVH from '../hooks/useDetectDVH';

// Images
import winghausLogoTransparent from '../img/winghaus-watermark.svg';
import clearBtn from '../img/clear_field.svg';
import TnCPDF from '../CC_TnCs.pdf';

// Utils
import { validateEmail, validateMobileNumber } from '../utils';

// Api
import { checkDuplicateTeamName, checkExistingEmail, createPlayer, profanityCheck } from '../api/players';

export default function SignUp({ userData, setUserData, setStep }) {
    useDetectDVH()
    
    const [ formErrors, setFormErrors ] = useState({team_name: '', full_name: '', email_address: '', mobile_number: '', termsOptIn: '', existing_email: false})

    /* revalidate a form
     * The intention is that it is set to "true" after a failed form submission
     * this is so that, as users attempt to correct their mistakes, they get feedback once they de-select a field
    */
    const [ revalidateForm, setRevalidateForm] = useState(false);

    const [showTerms, setShowTerms] = useState(false);

    const [validateLocalStorage, setValidateLocalStorage] = useState(false);


    // The below 4 functions are for updating the userData state on user input
    function handleChange(event) {
        setUserData({...userData, [event.target.name]: event.target.value})
    }
    
    function handleCheckboxChange(event) {
        if (!formErrors.existing_email) {
            setUserData({...userData, [event.target.name]: event.target.checked})

            // If revalidateForm is TRUE, then throw an error if the user hasn't selected/ is deselecting the field
            if(event.target.name === 'termsOptIn' && revalidateForm && !event.target.checked) {
                setFormErrors({ ...formErrors, [event.target.name]: 'Please accept the terms & conditions.' });
            } else if(event.target.name === 'termsOptIn' && revalidateForm && event.target.checked) {
                setFormErrors({ ...formErrors, [event.target.name]: '' });
            }
        }
    }

    function handleClearInput(event, target) {
        event.preventDefault();
        setUserData({...userData, [target]: ''})
        
        // If revalidateForm is TRUE, then clear validation errors when the field is cleared 
        if (target === 'email_address' && formErrors.existing_email) {
            setFormErrors({ ...formErrors, [target]: '', existing_email: false });
        } else {
            setFormErrors({ ...formErrors, [target]: '' });
        }
    }

    async function validateFields(fieldName) {
        const errors = await validateForm();
        if (errors.existing_email) {
            setFormErrors({...formErrors, [fieldName]: errors[fieldName] || '', existing_email: true});
        } else {
            setFormErrors({ ...formErrors, [fieldName]: errors[fieldName] || '', existing_email: false });
        }
        setValidateLocalStorage(false)
    }

    async function validateForm() {
        let errors = {}
        // Check form fields are valid
        
        // Check for profanity
        const teamNameProfanity = await profanityCheck(userData.team_name);
        // Check if the team name is unique
        const duplicateTeam = await checkDuplicateTeamName(userData.team_name, userData.email_address);
        if(duplicateTeam) {
            errors.team_name = 'This team name already exists, please choose another.';
        } else if(userData.team_name.length === 0 || userData.team_name === '') {
            errors.team_name = 'Please enter a team name';
        } else if(teamNameProfanity.isBadWords) {
            errors.team_name = 'Please choose a different team name. The name you\'ve entered contains inappropriate or offensive content.';
        }

        // Check for profanity
        const nameProfanity = await profanityCheck(userData.full_name);
        if(userData.full_name.length === 0 || userData.full_name === '') {
            errors.full_name = 'Please add your name.'
        } else if (nameProfanity.isBadWords) {
            errors.full_name = 'Please enter a different name. The name you\'ve entered contains inappropriate or offensive content.';
        }

        if (!validateEmail(userData.email_address)) {
            errors.email_address = 'Please enter a valid email address.';
        }

        const existingEmail = await checkExistingEmail(userData.email_address);
        if (existingEmail) {
            errors.existing_email = true;
            setUserData({...userData, ...existingEmail, full_name: existingEmail.attributes.name, team_name: existingEmail.attributes.teamName, mobile_number: existingEmail.attributes.mobile || '', state: existingEmail.attributes.location, termsOptIn: true});
        }

        if (userData.mobile_number && !validateMobileNumber(userData.mobile_number)) {
            errors.mobile_number = 'Please enter a valid mobile number.';
            if (String(userData.mobile_number).startsWith('61')) {
                errors.mobile_number = `Mobile doesn't allow +61 or 61 mobile masks.`;
            }
        }

        if(!userData.termsOptIn) {
            errors.termsOptIn = 'Please accept the terms & conditions.'
        }
        
        return errors;
    }

    async function handleSubmit() {
        // event.preventDefault();

        const errors = await validateForm();
        if(Object.keys(errors).length === 0) {
            console.log('Form submitted');
            try {
                localStorage.setItem('userData', JSON.stringify(userData));
                const data = {
                    name: userData.full_name,
                    email: userData.email_address,
                    mobile: userData.mobile_number,
                    teamName: userData.team_name,
                    isReceiveMarketing: userData.marketingOptIn,
                    location: userData.state
                }
                if (!data.mobile) {
                    delete data.mobile
                }
                const player = await createPlayer(data);
                console.log(player);
                setUserData(player.data);
                setStep('jersey');
            } catch (e) {
                console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
            }
        } else if (Object.keys(errors).length === 1 && errors.existing_email) {
            setUserData({id: userData.id, attributes: userData.attributes})
            setStep('jersey');
        } else {
            // set error messages to display in-line on page
            setFormErrors(errors);
            setRevalidateForm(true); // begin validating form as user updates fields
        }
    }

    useEffect(() => {
        const savedData = localStorage.getItem('userData');
        if (savedData) {
            setUserData(JSON.parse(savedData));
            setValidateLocalStorage(true);
        }
    }, [])

    useEffect(() => {
        if (validateLocalStorage) {
            validateFields('email_address');
        }
    }, [validateLocalStorage])

    return(<>
        <div className='grassBg absolute top-0 left-0 z-[999] w-full h-[100dvh] flex flex-col items-center px-4 py-6 overflow-y-scroll justify-between'>
            {!showTerms && (
                <>
                    <div>
                        <h1 className='mb-1 text-3xl'>Create Your Team</h1>
                        <p className='intro text-lg'>Please input your details below. Your team name will be visible on the leaderboard.</p>

                        <form className='mt-4 w-full flex-col space-y-4 text-center'>

                        {/* EMAIL ADDRESS */}
                        <div className='flex flex-col'>
                            {/* <div>
                                <div className='form-field-left flex-none'></div>
                                <div className='form-field-copy relative overflow-hidden flex items-center px-[6vw] w-[89.74vw] h-[12.483vw] mx-auto'>
                                    <div className="w-full h-full relative overflow-hidden">
                                        <input onBlur={()=> validateFields('email_address')} required type='email' name='email_address' value={userData.email_address} className='bg-transparent w-full h-full placeholder:text-black placeholder/70 outline-none' placeholder='Email'
                                        onChange={(e)=>{handleChange(e)}} />
                                        { !formErrors.existing_email && <button tabIndex="-1" onClick={(e)=>{handleClearInput(e, 'email_address')}}><img alt="img" src={clearBtn} className={`w-5 opacity-60 absolute top-[18px] transition-all active:scale-95 ${userData.email_address ? 'right-0' : '-right-5'}`} /></button> }
                                    </div>
                                </div>
                                <div className='form-field-right flex-none'></div>
                            </div> */}
                            <div className='flex flex-row'>
                                <div className='form-field-left flex-none'></div>
                                <div className='form-field-centre flex-grow relative overflow-hidden'>
                                    {/* <input onBlur={()=> revalidateForm && validateFields('team_name')} required type='text' name='team_name' value={userData.team_name} className='bg-transparent w-full h-full placeholder:text-black placeholder/70 outline-none disabled:text-black/40' placeholder='Name Your Team'
                                    onChange={(e)=>{handleChange(e)}} />
                                    { !formErrors.existing_email && <button tabIndex="-1" onClick={(e)=>{handleClearInput(e, 'team_name')}}><img alt="img" src={clearBtn} className={`w-5 opacity-60 absolute top-[18px] transition-all active:scale-95 ${userData.team_name ? 'right-0' : '-right-5'}`} /></button> } */}
                                    
                                    <input onBlur={()=> validateFields('email_address')} required type='email' name='email_address' value={userData.email_address} className='bg-transparent w-full h-full placeholder:text-black placeholder/70 outline-none' placeholder='Email'
                                        onChange={(e)=>{handleChange(e)}} />
                                    <button tabIndex="-1" onClick={(e)=>{handleClearInput(e, 'email_address')}}><img alt="img" src={clearBtn} className={`w-5 opacity-60 absolute top-[18px] transition-all active:scale-95 ${userData.email_address ? 'right-0' : '-right-5'}`} /></button>
                                </div>
                                <div className='form-field-right flex-none'></div>
                            </div>
                            { formErrors?.email_address && <>
                                <div className='bg-[#CE2927] flex text-white text-[0.9rem] mx-3 leading-4 py-2 relative text-center'>
                                    <div className='w-full'>{formErrors.email_address}</div>
                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className='absolute right-10 -top-[19px]'>
                                        <path d="M 10 0 L 20 20 L 0 20 Z" fill="#CE2927" className=''/>
                                    </svg>
                                </div>
                            </> }
                            { formErrors?.existing_email && <>
                                <div className='bg-black/30 flex text-white text-[0.9rem] mx-4 leading-4 py-3 relative text-center'>
                                    <div className='w-full'>An account registered to this email address already exists. Press next to continue playing as your current team.</div>
                                </div>
                            </> }
                        </div>

                        {/* TEAM NAME */}
                        <div className='flex flex-col'>
                            <div className='flex flex-row'>
                                <div className='form-field-left flex-none'></div>
                                <div className='form-field-centre flex-grow  relative overflow-hidden'>
                                    <input disabled={formErrors.existing_email} onBlur={()=> revalidateForm && validateFields('team_name')} required type='text' name='team_name' value={userData.team_name} className='bg-transparent w-full h-full placeholder:text-black placeholder/70 outline-none disabled:text-black/40' placeholder='Name Your Team'
                                    onChange={(e)=>{handleChange(e)}} />
                                    { !formErrors.existing_email && <button tabIndex="-1" onClick={(e)=>{handleClearInput(e, 'team_name')}}><img alt="img" src={clearBtn} className={`w-5 opacity-60 absolute top-[18px] transition-all active:scale-95 ${userData.team_name ? 'right-0' : '-right-5'}`} /></button> }
                                </div>
                                <div className='form-field-right flex-none'></div>
                            </div>
                            { formErrors?.team_name && <>
                                <div className='bg-[#CE2927] flex text-white text-[0.9rem] mx-3 leading-4 py-2 relative text-center'>
                                    <div className='w-full'>{formErrors.team_name}</div>
                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className='absolute right-10 -top-[19px]'>
                                        <path d="M 10 0 L 20 20 L 0 20 Z" fill="#CE2927" className=''/>
                                    </svg>
                                </div>
                            </> }
                        </div>
                        
                {/* FULL NAME */}
                <div className='flex flex-col'>
                    <div className='flex flex-row'>
                        <div className='form-field-left flex-none'></div>
                        <div className='form-field-centre flex-grow  relative overflow-hidden'>
                            <input disabled={formErrors.existing_email} onBlur={()=> revalidateForm && validateFields('full_name')} required type='text' name='full_name' value={userData.full_name} className={`bg-transparent w-full h-full placeholder:text-black placeholder/70 outline-none disabled:text-black/40`} placeholder='Full Name'
                            onChange={(e)=>{handleChange(e)}} />
                            { !formErrors.existing_email && <button tabIndex="-1" onClick={(e)=>{handleClearInput(e, 'full_name')}}><img alt="img" src={clearBtn} className={`w-5 opacity-60 absolute top-[18px] transition-all active:scale-95 ${userData.full_name ? 'right-0' : '-right-5'}`} /></button> }
                        </div>
                        <div className='form-field-right flex-none'></div>
                    </div>
                    { formErrors?.full_name && <>
                        <div className='bg-[#CE2927] flex text-white text-[0.9rem] mx-3 leading-4 py-2 relative text-center'>
                            <div className='w-full'>{formErrors.full_name}</div>
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className='absolute right-10 -top-[19px]'>
                                <path d="M 10 0 L 20 20 L 0 20 Z" fill="#CE2927" className=''/>
                            </svg>
                        </div>
                    </> }
                </div>


                {/* Mobile Number */}
                <div className='flex flex-col'>
                    <div className='flex flex-row'>
                        <div className='form-field-left flex-none'></div>
                        <div className='form-field-centre flex-grow  relative overflow-hidden'>
                            <input disabled={formErrors.existing_email} onBlur={()=> revalidateForm && validateFields('mobile_number')} type='number' name='mobile_number' value={userData.mobile_number} className='bg-transparent w-full h-full placeholder:text-black placeholder/70 outline-none disabled:text-black/40' placeholder='Mobile Number (optional)'
                            onChange={(e)=>{handleChange(e)}} />
                            { !formErrors.existing_email && <button tabIndex="-1" onClick={(e)=>{handleClearInput(e, 'mobile_number')}}><img alt="img" src={clearBtn} className={`w-5 opacity-60 absolute top-[18px] transition-all active:scale-95 ${userData.mobile_number ? 'right-0' : '-right-5'}`} /></button> }
                        </div>
                        <div className='form-field-right flex-none'></div>
                    </div>
                    { formErrors?.mobile_number && <>
                        <div className='bg-[#CE2927] flex text-white text-[0.9rem] mx-3 leading-4 py-2 relative text-center'>
                            <div className='w-full'>{formErrors.mobile_number}</div>
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className='absolute right-10 -top-[19px]'>
                                <path d="M 10 0 L 20 20 L 0 20 Z" fill="#CE2927" className=''/>
                            </svg>
                        </div>
                    </> }
                </div>

                {/* STATE */}
                <div className='flex flex-row px-3.5 pb-2'>
                    <button onClick={(e)=>{
                        e.preventDefault();
                        if (!formErrors.existing_email) {
                            setUserData({...userData, state: 'NSW'})
                        }
                    }}
                        className='relative'
                        >
                        <svg width="100%" height="55" viewBox="0 0 1000 240" preserveAspectRatio="xMidYMid meet">
                            <polygon points="20,0 70,240 1000,240 1000,0" fill={`${userData.state === 'NSW' ? '#ffffff' : 'transparent'}`} stroke='#A6DBD3' strokeWidth={30} />
                        </svg>
                        <h3 className={`absolute mx-auto top-0 h-full w-full pt-3.5 font-jock transition-all duration-150 ${userData.state === 'NSW' ? 'text-[#2e611b]' : 'text-[#A6DBD3]/50 '}`}>NSW</h3>
                    </button>
                    <button onClick={(e)=>{
                        e.preventDefault();
                        if (!formErrors.existing_email) {
                            setUserData({...userData, state: 'QLD'})
                        }
                    }}
                        className='relative'
                        >
                        <svg width="100%" height="55" viewBox="0 0 1000 240" preserveAspectRatio="xMidYMid meet">
                            <polygon points="0,0 0,240 930,240 980,0" fill={`${userData.state === 'QLD' ? '#ffffff' : 'transparent'}`} stroke='#A6DBD3' strokeWidth={30} />
                        </svg>
                        <h3 className={`absolute mx-auto top-0 h-full w-full pt-3.5 font-jock transition-all duration-150 ${userData.state === 'QLD' ? 'text-[#2e611b]' : 'text-[#A6DBD3]/50 '}`}>QLD</h3>
                    </button>
                </div>

                {/* Terms & Conditions Checkbox */}
                <div className='container_check relative'>
                    <label htmlFor="termsOptIn" className=''>
                        <input disabled={formErrors.existing_email} type='checkbox' id="termsOptIn" name='termsOptIn' className='ml-2' checked={userData.termsOptIn} onChange={handleCheckboxChange} required />
                        <span className='checkmark ml-4'></span>
                        <span className='-ml-2'>I agree to the <a href={TnCPDF} target='_blank' className='underline'>terms & conditions</a> and am 18 years of age or older.</span>
                    </label>
                </div>
                { formErrors?.termsOptIn && <>
                    <div className='bg-[#CE2927] flex text-white text-[0.9rem] mx-3 leading-4 py-2 relative text-center'>
                        <div className='w-full'>{formErrors.termsOptIn}</div>
                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className='absolute left-1.5 -top-[19px]'>
                            <path d="M 10 0 L 20 20 L 0 20 Z" fill="#CE2927" className=''/>
                        </svg>
                    </div>
                </> }


                {/* Marketing Emails*/}
                <p className='text-sm text-left px-5'>I understand that by playing Chick & Chase, I consent to receiving marketing communications from Winghaus. <br /><br />Winghaus practices the responsible service of alcohol. Drink Responsibly.</p>

    
                        </form>
                    </div>
                    {/* FOOTER */}
                    <div className='mt-20 flex flex-row place-content-between w-full'>
                        <img alt="img" src={winghausLogoTransparent} className='w-[53px]' />
                        {/* <SmallPrimaryButton clickEvent={handleSubmit} >Next</SmallPrimaryButton> */}
                        <PrimaryButton 
                            size="small" 
                            type="primary" 
                            clickEvent={handleSubmit}
                            className="!w-[164.95px] !text-3xl"
                            >Next
                        </PrimaryButton>
                    </div>
                </>)}
        </div>
        <TermsModal visible={showTerms} onClose={() => setShowTerms(false)}/>
    </>)
}
