/* 
 * Mobile Warning Screen: This screen should be displayed when the a desktop device is detected.
 */
import { motion, AnimatePresence } from "framer-motion";

import chickLogo from '../img/cnc_logo_lrg@3x.png';
import winghausLogoRed from '../img/winghaus-full-logo.png';

export default function MobileOnly() {

    return(<>
        <div className='absolute top-0 left-0 z-[999] w-full h-[100dvh] flex flex-col items-center overflow-scroll bg-gray-800'>
            <div className="my-auto">
                <motion.img
                    initial={{scale: 0.7, opacity: 0}}
                    animate={{scale: 1, opacity: 1, transition: {duration: 1, delay: 0}}}
                    src={chickLogo}
                    className='w-[20.58dvh] mx-auto'
                />

                <div className='mt-14 flex flex-col text-cente'>
                    <motion.h4 initial={{opacity: 0, y:-50}} animate={{opacity: 1, y:0, transition: {delay: 1}}} className="text-[1.895dvh]">This game is designed to be played on a mobile device.</motion.h4>
                </div>
            </div>
        </div>
    </>)
}