import service from "./service";

export async function sendInvite(data) {
  try {
    const res = await service.post('/invites', {
      data: data
    });
    return res.data
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}