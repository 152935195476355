// Imports from libraries
import { useRef } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import { RepeatWrapping } from "three"
import { Plane, useTexture } from "@react-three/drei";

// Local imports
import { trackLengthGlobal, generateObstacles, generatePickups } from "../utils";
import { Defender } from "../models/Defender";

export function Track1({ setTriesScored, obstaclesTrack1, setTimer, 
  pickupsTrack1, setPickupsTrack1,
  collected, setObstaclesTrack1, trackSpeed, metresGained, isGameOver }) {
  const trackRef = useRef();
  const { viewport } = useThree(); // Get viewport size
  // Adjust the track size based on viewport height
  const trackLength = viewport.height * trackLengthGlobal; // Scale factor to ensure full coverage

  useFrame(() => {
    if (trackRef.current && !isGameOver) {
      trackRef.current.position.z += trackSpeed;
      if (trackRef.current.position.z > trackLength / 2) {
        // setTriesScored(tries => tries + 1);
        // setTimer(time => time + fieldCompleteTimeBonus);
        const obstacles = generateObstacles(metresGained);
        for (let i = 0; i < obstacles.length; i++) {
          obstacles[i][4] = -trackLength * 1.5 - obstacles[i][1]
        }
        setObstaclesTrack1(obstacles); // Refresh obstacles
        setPickupsTrack1(generatePickups(metresGained));
        setTimeout(() => {
          trackRef.current.position.z = -trackLength * 1.5; // Move track along
        }, 10)
      } else {
        obstaclesTrack1.forEach(obstacle => {
          const tempSpeed = trackSpeed * Math.random()
          obstacle[1] -= tempSpeed
          obstacle[4] = trackRef.current.position.z - obstacle[1] - tempSpeed // Move obstacle along with track
          // console.log('(trackRef.current.position.z', trackRef.current.position.z)
          // console.log('obstacle[1]', obstacle[1])
          // console.log('obstacle[4]', obstacle[4])
        });
        setObstaclesTrack1(obstaclesTrack1)
        pickupsTrack1.forEach(obstacle => {
          obstacle[4] = trackRef.current.position.z - obstacle[1] // Move obstacle along with track
          // console.log('(trackRef.current.position.z', trackRef.current.position.z)
          // console.log('obstacle[1]', obstacle[1])
          // console.log('obstacle[4]', obstacle[4])
        });
        setPickupsTrack1(pickupsTrack1)
      }
    }
  });

  const background = useTexture('textures/grass_texture_v1.jpg');
  background.wrapS = RepeatWrapping;
  background.wrapT = RepeatWrapping;
  background.repeat.set(16, 4);

  const mark = useTexture('textures/grass_texture_v1_LQ.png')

  const beer = useTexture('textures/beerr 1.png');
  const chickenWings = useTexture('textures/cw 1.png');
  const largeChickenWings = useTexture('textures/lcw 1.png');

  const getTexture = (type) => {
    if (type === 'chickenWings') return chickenWings
    if (type === 'beer') return beer
    if (type === 'largeChickenWings') return largeChickenWings
  }

  const getSize = (type) => {
    if (type === 'chickenWings') return [10, 10, 20, 20]
    if (type === 'beer') return [8, 8, 20, 20]
    if (type === 'largeChickenWings') return [14, 14, 20, 20]
  }

  return (
    <group ref={trackRef} position={[0, 0, -trackLength/2]}>
      <Plane receiveShadow={true} args={[50, 50, 10, 10]} rotation={[-Math.PI / 2, 0, 0]} position={[0, 1, 450]}>
        <meshStandardMaterial attach="material" map={mark} transparent />
      </Plane>
      <Plane receiveShadow={true} args={[50, 50, 10, 10]} rotation={[-Math.PI / 2, 0, 0]} position={[0, 1, -55]}>
        <meshStandardMaterial attach="material" map={mark} transparent />
      </Plane>
      <Plane receiveShadow={true} args={[3000, trackLength, 10, 10]} rotation={[-Math.PI / 2, 0, 0]}>
        <meshStandardMaterial attach="material" map={background} />
        {obstaclesTrack1.map((position, index) => (
          <Defender key={`track-1-obstacle-${index}`} isGameOver={isGameOver} position={position.slice(0, 5)} rotation={[Math.PI / 2, 0, 0]} scale={1.85} />
        ))}
        {pickupsTrack1.map((position, index) => (
          <Plane visible={!collected.includes(position[6])} receiveShadow={true} key={`track-1-obstacle-${index}`} args={getSize(position[7])} position={[position[0], position[1]+10, position[2]+6]} rotation={[Math.PI / 2, 0, 0]}>
            <meshToonMaterial transparent map={getTexture(position[7])} />
          </Plane>
        ))}
      </Plane>
    </group>
  );
}

export function Track2({ setTriesScored, obstaclesTrack2, setTimer, collected, 
  setPickupsTrack2, pickupsTrack2,
  setObstaclesTrack2, metresGained, trackSpeed, isGameOver }) {
  const trackRef = useRef();
  const { viewport } = useThree(); // Get viewport size

  // Adjust the track size based on viewport height
  const trackLength = viewport.height * trackLengthGlobal; // Scale factor to ensure full coverage

  useFrame(() => {
    if (trackRef.current && !isGameOver) {
      trackRef.current.position.z += trackSpeed;
      if (trackRef.current.position.z > trackLength / 2) {
        // setTriesScored(tries => tries + 1);
        // setTimer(time => time + fieldCompleteTimeBonus);
        const obstacles = generateObstacles(metresGained);
        for (let i = 0; i < obstacles.length; i++) {
          obstacles[i][4] = -trackLength * 1.5 - obstacles[i][1]
        }
        setObstaclesTrack2(obstacles);
        setPickupsTrack2(generatePickups(metresGained));
        setTimeout(() => {
          trackRef.current.position.z = -trackLength * 1.5; // Move track along
        }, 10)
      } else {
        obstaclesTrack2.forEach(obstacle => {
          if (obstacle[7] === 'obstacle') {
            const tempSpeed = trackSpeed * Math.random()
            obstacle[1] -= tempSpeed
            obstacle[4] = trackRef.current.position.z - obstacle[1] - tempSpeed
          } else {
            obstacle[4] = trackRef.current.position.z - obstacle[1]
          }
          // console.log('(trackRef.current.position.z', trackRef.current.position.z)
          // console.log('obstacle[1]', obstacle[1])
          // console.log('obstacle[4]', obstacle[4])
        });
        setObstaclesTrack2(obstaclesTrack2)
        pickupsTrack2.forEach(obstacle => {
          obstacle[4] = trackRef.current.position.z - obstacle[1] // Move obstacle along with track
          // console.log('(trackRef.current.position.z', trackRef.current.position.z)
          // console.log('obstacle[1]', obstacle[1])
          // console.log('obstacle[4]', obstacle[4])
        });
        setPickupsTrack2(pickupsTrack2)
      }
    }
  });
  
  const background = useTexture('textures/grass_texture_v1.jpg');
  background.wrapS = RepeatWrapping;
  background.wrapT = RepeatWrapping;
  background.repeat.set(16, 4);

  const mark = useTexture('textures/grass_texture_v1_LQ.png')

  const beer = useTexture('textures/beerr 1.png');
  const chickenWings = useTexture('textures/cw 1.png');
  const largeChickenWings = useTexture('textures/lcw 1.png');

  const getTexture = (type) => {
    if (type === 'chickenWings') return chickenWings
    if (type === 'beer') return beer
    if (type === 'largeChickenWings') return largeChickenWings
  }

  const getSize = (type) => {
    if (type === 'chickenWings') return [10, 10, 20, 20]
    if (type === 'beer') return [8, 8, 20, 20]
    if (type === 'largeChickenWings') return [14, 14, 20, 20]
  }

  return (
    <group ref={trackRef} position={[0, 0, -trackLength * 1.5]}>
      <Plane receiveShadow={true} args={[50, 50, 10, 10]} rotation={[-Math.PI / 2, 0, 0]} position={[0, 1, 450]}>
        <meshStandardMaterial attach="material" map={mark} transparent />
      </Plane>
      <Plane receiveShadow={true} args={[50, 50, 10, 10]} rotation={[-Math.PI / 2, 0, 0]} position={[0, 1, -55]}>
        <meshStandardMaterial attach="material" map={mark} transparent />
      </Plane>
      <Plane receiveShadow={true} args={[3000, trackLength, 10, 10]} rotation={[-Math.PI / 2, 0, 0]}>
        <meshStandardMaterial attach="material" map={background} />
        {obstaclesTrack2.map((position, index) => (
          <Defender key={`track-2-obstacle-${index}`} isGameOver={isGameOver} position={position.slice(0, 5)} rotation={[Math.PI / 2, 0, 0]} scale={1.85} />
        ))}
        {pickupsTrack2.map((position, index) => (
          <Plane visible={!collected.includes(position[6])} receiveShadow={true} key={`track-2-pickup-${index}`} args={getSize(position[7])} position={[position[0], position[1]+10, position[2]+6]} rotation={[Math.PI / 2, 0, 0]}>
            <meshToonMaterial transparent map={getTexture(position[7])} />
          </Plane>
        ))}
      </Plane>
    </group>
  );
}