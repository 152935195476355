/* 
 * Team colours: once the sign up form has been completed, players use this page to select their team colours
 * the selected colours will then load the corresponding texture that the chicken will wear in the game
 * Note: Background image is placeholder as the intention is the interface will animate, while the background remains static between 'pages'
 */
import { motion } from "framer-motion";
import { useState } from 'react';
import frameImg from '../img/frame.png';
import chicken2Img from '../img/chicken2.png';
import playImg from '../img/play.png';
import { PrimaryButton } from './buttons';
import useDetectDVH from "../hooks/useDetectDVH";

// Images
import winghausLogoTransparent from '../img/winghaus-watermark.svg';
import arrowTop from '../img/arrow-top.svg'
import arrowBottom from '../img/arrow-bottom.svg'

import onboarding1 from '../onboarding/onboarding_1.jpg';
import onboarding2 from '../onboarding/onboarding_2.mp4';
import onboarding3 from '../onboarding/onboarding_4.mp4';
import onboarding4 from '../onboarding/onboarding_3.mp4';
import onboarding5 from '../onboarding/onboarding_5.mp4';

export default function HowToPlay({ onSubmit }) {
    const [ pageCounter, setPageCounter] = useState(0);
    const [ animDirection, setAnimDirection] = useState(1); // if back button is hit, this is -1 to make it animate from left to right rather than right to left

    useDetectDVH()

    const incPage = () => {
        if(pageCounter < 4) {
            setPageCounter(pageCounter + 1)
            setAnimDirection(1)
        }
    }

    const decPage = () => {
        if(pageCounter > 0) {
            setPageCounter(pageCounter - 1)
            setAnimDirection(-1)
        }
    }

    function whiteCircle(opacity) {
        return(
            <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" className={` transition-opacity duration-100 ${opacity === 1 ? "opacity-100" : "opacity-50"}`}>
                <circle cx="5" cy="5" r="5" fill="white" />
            </svg>
        )
    }

    return(<>
        <div className='grassBg absolute top-0 left-0 z-[999] w-full h-[100dvh] flex flex-col justify-between items-center px-4 py-6 overflow-scroll'>
            <div>
            <h1 className='font-jock text-3xl mb-4'>HOW TO PLAY</h1>
            {/* Image carousel container */}
            <div>
              {/* Directions */}
              <Guides idx={pageCounter} direction={animDirection} />

              {/* navigation */}
              <div className='flex flex-row place-content-center my-[5vw] align-middle h-11'>
                  <button className={`relative w-14 group ${pageCounter == 0 ? 'opacity-50' : 'opacity-100'}`} onClick={decPage}>
                      <img src={arrowBottom} className='absolute left-3 top-1 drop-shadow-xl rotate-180' alt="arrow bottom"/>
                      <img src={arrowTop} className='absolute left-0.5 top-0 group-active:top-0 group-active:left-1 drop-shadow-md rotate-180' alt="arrow top"/>
                  </button>
                  <div className='flex flex-row space-x-1.5 mx-8 pt-2 my-auto'>
                      { whiteCircle(pageCounter === 0 ? 1 : 0.5) }
                      { whiteCircle(pageCounter === 1 ? 1 : 0.5) }
                      { whiteCircle(pageCounter === 2 ? 1 : 0.5) }
                      { whiteCircle(pageCounter === 3 ? 1 : 0.5) }
                      { whiteCircle(pageCounter === 4 ? 1 : 0.5) }
                  </div>
                  <button className={`relative w-14 group ${pageCounter == 4 ? 'opacity-50' : 'opacity-100'}`} onClick={incPage}>
                      <img src={arrowBottom} className='absolute left-3 top-1 drop-shadow-xl' alt="arrow bottom"/>
                      <img src={arrowTop} className='absolute left-0 top-0 group-active:top-1 group-active:-left-0.5 drop-shadow-md' alt="arrow top"/>
                  </button>
              </div>
            </div>

            {/* HOW TO PLAY INSTRUCTIONS */}
            { pageCounter === 0 && <>
                <div
                key={0}
                initial={{opacity: 0, x: 100 * animDirection }}
                animate={{opacity: 1, x: 0 }}
                exit={{opacity: 0, x: -100 * animDirection }}
                >
                    <p className="text-[4vw] px-2 mx-auto text-center mt-3">Get ready to dodge, chip and sprint in the ultimate Winghaus dash. See how far you can wing it down the field, collecting points to become top chook. Take out the top spot on the leaderboard to win great prizes!</p>
                </div>
            </>}

            { pageCounter === 1 && <>
                <div
                key={0}
                initial={{opacity: 0, x: 100 * animDirection }}
                animate={{opacity: 1, x: 0 }}
                exit={{opacity: 0, x: -100 * animDirection }}
                
                >
                    <h3 className='text-center mt-3'>Falling fowl</h3>
                    <p className="text-[4vw] px-2 mx-auto text-center">If a Winghaus opponent tackles you, you'll lose a life. Once you lose 3 lives, your game is cooked.</p>
                </div>
            </>}

            { pageCounter === 2 && <>
                <div
                key={0}
                initial={{opacity: 0, x: 100 * animDirection }}
                animate={{opacity: 1, x: 0 }}
                exit={{opacity: 0, x: -100 * animDirection }}
                >
                    <h3 className='text-center mt-3'>Chick &amp; Chips</h3>
                    <p className="text-[4vw] px-2 mx-auto text-center">Avoid being tackled by swiping up on your chicken to chip the ball - careful to catch the ball by staying under it, otherwise you'll lose a life.</p>
                </div>
            </>}

            { pageCounter === 3 && <>
                <div
                key={0}
                initial={{opacity: 0, x: 100 * animDirection }}
                animate={{opacity: 1, x: 0 }}
                exit={{opacity: 0, x: -100 * animDirection }}
                >
                    <h3 className='text-center mt-3'>Poultry in motion</h3>
                    <p className="text-[4vw] px-2 mx-auto text-center">Swipe left and right on your chicken to deftly dodge opponents and stay in the game.</p>
                </div>
            </>}
            
            { pageCounter === 4 && <>
                <div
                key={0}
                initial={{opacity: 0, x: 100 * animDirection }}
                animate={{opacity: 1, x: 0 }}
                exit={{opacity: 0, x: -100 * animDirection }}
                >
                    <h3 className='text-center mt-3'>Scramble for points</h3>
                    <p className="text-[4vw] px-2 mx-auto text-center">Rack up points by collecting mouth-watering chicken wings and cold beers. Will you be top of the pecking order, or handed the wooden spoon for a poultry effort?</p>
                </div>
            </>}

            </div>


            {/* FOOTER */}
            <div className='mt-[12vw] flex flex-row place-content-between w-full'>
                <img alt="img" src={winghausLogoTransparent} className='w-[53px]' />
                <PrimaryButton 
                    size="small" 
                    type="primary" 
                    className="!w-[164.95px]"
                    clickEvent={onSubmit}
                    >
                        { pageCounter === 4 ? "Next" : "Skip" }
                </PrimaryButton>
            </div>
        </div>
    </>)
}

function Guides({idx, direction}) {
    let onboardingClips = [onboarding1,onboarding2,onboarding3,onboarding4,onboarding5]
return(<>
      <motion.div
          key={idx}
          initial={{opacity: 0, x: 100 * direction }}
          animate={{opacity: 1, x: 0 }}
          exit={{opacity: 0, x: -100 * direction }}
          className='relative'
      >
          { idx === 0 ? <img src={onboardingClips[idx]} className='w-2/3 mx-auto' /> : 
          <video
          src={onboardingClips[idx]}
        //   autoPlay
          loop
          muted
          playsInline
          webkit-playsinline
          controls={true} 
          className='w-2/3 mx-auto'
        >
          Your browser does not support the video tag.
        </video>
         }
          {/* <img src={frameImg} className="w-[89.637vw] h-[116.58vw] mx-auto" /> */}
      </motion.div>
  </>)
}