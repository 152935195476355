import { useEffect } from "react";

export default function useDetectDVH( bgClass = '.grassBg') {
  useEffect(() => {
    const grassBg = document.querySelector(bgClass)

    if ('CSS' in window && 'supports' in window.CSS) {
      if (!window.CSS.supports('height', '100dvh')) {
        console.log('浏览器支持 vh 单位');
        console.log('grassBg: ', grassBg)
        grassBg.style.height = window.innerHeight + 'px'
      }
    }
  }, [])
}