import logoImg from '../img/winghaus-full-logo.png'
import wingImg from '../img/wing.png'
import { PrimaryButton } from './buttons'
import { motion } from 'framer-motion';
import useDetectDVH from '../hooks/useDetectDVH';


export default function BetterLuck({toLeaderboard, score}) {
  useDetectDVH()
  return (<>
    <div className={`grassBg absolute top-0 left-0 z-[999] h-[100dvh] w-full py-6 overflow-scroll`}>
      <div className='relative betterLuckBg w-[89vw] pt-[20vw] h-[151.15vw] text-center mx-auto'>
        <img src={logoImg} className="w-[13.95vw] absolute top-0 -translate-y-[2vw] left-1/2 transform -translate-x-1/2" />
        <h2 className='font-jock text-[6vw] mb-3'>Game over</h2>
        <h2 className="font-jock text-white text-[5.14vw]">YOUR SCORE</h2>
        <motion.div initial={{y: 50, opacity: 0}} animate={{y: 0, opacity: 1}} className="text-[#A6DBD3] font-jock text-[12.339vw] leading-none">{score || '000000'}</motion.div>
        <img src={wingImg} className='w-[67.47vw] mx-auto mt-[12vw]' />
      </div>
      <PrimaryButton 
        size="long" 
        className="!w-[296.77px] !mt-[5vw]"
        type={'primary'}
        url={'https://winghaus.com.au/'}
      >
        VISIT WEBSITE
      </PrimaryButton>
      <PrimaryButton 
        size="long" 
        className="!w-[264.33px] !mt-[5vw]"
        clickEvent={toLeaderboard}
      >
        LEADERBOARD
      </PrimaryButton>
    </div>
  </>)
}