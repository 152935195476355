import inputImg from '../img/input.svg'
import clearBtn from '../img/clear_field.svg';

export default function CustomInput({ placeholder = '', onClear, error, value, name, register = {}, ...otherProps }) {
  return <div className={`w-full relative ${otherProps.className || ''}`}>
    <img src={inputImg} className="w-full" />
    <input 
      {...otherProps}
      { ...register }
      className="absolute w-[85%] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 outline-none text-black placeholder:text-black text-[4.6vw]" 
      placeholder={placeholder}
    />
    {
      value && (
        <button 
          type="button"
          className='absolute right-[7%] top-1/2 transform -translate-y-1/2'
          onClick={onClear}
        ><img alt="img" src={clearBtn} className={`w-5 opacity-60  transition-all active:scale-95`} /></button>
      )
    }
    { error && <>
        <div className='absolute top-[110%] left-0 bg-[#CE2927] flex text-white text-[0.9rem] mx-3 leading-4 py-2 text-center w-[90%]'>
            <div className='w-full'>{error.message}</div>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className='absolute right-10 -top-[20px]'>
                <path d="M 10 0 L 20 20 L 0 20 Z" fill="#CE2927" className=''/>
            </svg>
        </div>
    </> }
  </div>
}