export const trackLengthGlobal = 10;
export const bumpPath = '/sound/bump.wav'
export const coinPath = '/sound/coin.wav'
export const dashPath = '/sound/dash.aac'
export const crowdPath = '/sound/crowd.aac'
export const itemPath = '/sound/item_collect.aac'
export const whistlePath = '/sound/whistle.mp3'
export const menuMusicPath = '/sound/background-music.mp3'
export const collectibles = ['beer', 'chickenWings', 'largeChickenWings'];
export const pointSettings = {
  normal: 25,
  beer: 50,
  chickenWings: 100,
  largeChickenWings: 200,
  ballCatch: 50
}

export const randomId = () => {
  return Math.random().toString(36).substr(2, 9);
}

export const numObstacle = (metresGained) => {
  const m = Math.floor(metresGained)
  if (m <= 10) return 0
  else if (m <= 100) return 4
  else if (m <= 200) return 6
  else if (m <= 300) return 8
  else if (m <= 400) return 10
  else return 12
}

export const obstacleRate = (metresGained) => {
  const m = Math.floor(metresGained)
  if (m <= 300) return 0.5
  else if (m <= 400) return 0.6
  else if (m <= 500) return 0.7
  else return 0.8
}

export const generateObstacles = (metresGained) => {
  const obstacles = [];
  const gap = 50
  // const oRate = obstacleRate(metresGained)
  // Generate obstacles per track
  let i = 0;
  while (i < numObstacle(metresGained)) {
    const lane = Math.floor(Math.random() * 3) - 1;
    const pos = Math.random() * 900 - 450;
    if ((pos > -350 && Math.abs(pos - (-35)) > 200) // obstacle should not be where player is
      && !obstacles.find(x => x[0] === lane * 18 && Math.abs(x[1]-pos) < gap) // no obstacle exists at the same position
      && (obstacles.filter(x => Math.abs(x[1]-pos) < gap).length < 2 || Math.floor(metresGained) > 100) // no more than 2 lanes with obstacles at the same distance
      ) {
      obstacles.push([lane * 18, pos, 0, lane * 18, pos, 0, randomId(), 'obstacle']);
      i++;
    }
  }
  return obstacles;
};

export const generatePickups = (metresGained) => {
  const pickups = [];
  const gap = 50
  let i = 0;
  while (i < 8) {
    const lane = Math.floor(Math.random() * 3) - 1;
    const pos = Math.random() * 900 - 450;
    if ((pos > -350 && Math.abs(pos - (-35)) > 200) // obstacle should not be where player is
      && !pickups.find(x => x[0] === lane * 18 && Math.abs(x[1]-pos) < gap) // no obstacle exists at the same position
      && (pickups.filter(x => Math.abs(x[1]-pos) < gap).length < 2 || Math.floor(metresGained) > 100) // no more than 2 lanes with obstacles at the same distance
      ) {
        pickups.push([lane * 18, pos, 0, lane * 18, pos, 0, randomId(), collectibles[Math.floor(Math.random() * collectibles.length)]]);
      i++;
    }
  }
  return pickups;
}

  // The below 2 functions are to manage validating email and phone number
export function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function validateMobileNumber(number) {
    const numberRegex = /^\d{10}$/;
    return numberRegex.test(number) && !String(number).startsWith('61');
}

// Note: below is an array that matches the jersey tile displayed on this page, with the corresponding texture to use in the game
export const jerseyReference = [
  { jersey: "team_jersey_01.png", texture: "team_color_01.png" },
  { jersey: "team_jersey_02.png", texture: "team_color_02.png" },
  { jersey: "team_jersey_03.png", texture: "team_color_03.png" },
  { jersey: "team_jersey_04.png", texture: "team_color_04.png" },
  { jersey: "team_jersey_05.png", texture: "team_color_05.png" },
  { jersey: "team_jersey_06.png", texture: "team_color_06.png" },
  { jersey: "team_jersey_07.png", texture: "team_color_07.png" },
  { jersey: "team_jersey_08.png", texture: "team_color_08.png" },
  { jersey: "team_jersey_09.png", texture: "team_color_09.png" },
  { jersey: "team_jersey_10.png", texture: "team_color_10.png" },
  { jersey: "team_jersey_11.png", texture: "team_color_11.png" },
  { jersey: "team_jersey_12.png", texture: "team_color_12.png" },
  { jersey: "team_jersey_13.png", texture: "team_color_13.png" },
  { jersey: "team_jersey_14.png", texture: "team_color_14.png" },
  { jersey: "team_jersey_15.png", texture: "team_color_15.png" },
  { jersey: "team_jersey_16.png", texture: "team_color_16.png" }
];

export const speed = 4.5

export const getSpeed = (metresGained) => {
  if (metresGained >= 0 && metresGained <= 5) return 0.6 * speed
  else if (metresGained > 5 && metresGained <= 10) return 0.7 * speed
  else if (metresGained > 10 && metresGained <= 20) return 0.8 * speed
  else if (metresGained > 20 && metresGained <= 40) return 0.9 * speed
  else if (metresGained > 40) return speed
}

export const laneWidth = 9;