/* 
 * This page defines various button components for the game
 */

import buttonForeground from '../img/button_top.png';
import buttonBg from '../img/button_bottom.png';
import longButtonForeground from '../img/button_top_long.png';
import longButtonBg from '../img/button_bottom_long.png';
import greenButtonForeground from '../img/button_top_green.png'
import greenButtonBg from '../img/button_bottom_green.png'
import longGreenButtonForeground from '../img/button_top_green_long.png'
import longGreenButtonBg from '../img/button_bottom_green_long.png'


export function SmallPrimaryButton( {children, url, clickEvent} ) {
    if(url) {
        return(
            <a href={url} className="select-none w-40 h-[60px] group flex flex-row transition-all duration-1000 relative font-jock">
                <h3 className='relative z-30 mx-auto my-auto text-2xl text-white font-bold drop-shadow-md group-active:drop-shadow-none group-active:top-2 group-active:left-1'>{ children }</h3>
                <img alt='button' src={ buttonForeground } className="w-40 absolute z-20 top-0 left-0 group-active:top-2 group-active:left-1 drop-shadow-md" />
                <img alt='button background' src={ buttonBg } className="w-40 absolute top-2 left-1 drop-shadow-lg" />
            </a>
        )
    } else if(clickEvent) {
        return(
            <button onClick={clickEvent} className="select-none w-40 h-[60px] group flex flex-row transition-all duration-1000 relative font-jock">
                <h3 className='relative z-30 mx-auto my-auto text-2xl text-white font-bold drop-shadow-md group-active:drop-shadow-none group-active:top-2 group-active:left-1'>{ children }</h3>
                <img alt='button' src={ buttonForeground } className="w-40 absolute z-20 top-0 left-0 group-active:top-2 group-active:left-1 drop-shadow-md" />
                <img alt='button background' src={ buttonBg } className="w-40 absolute top-2 left-1 drop-shadow-lg" />
            </button>
        )
    } else {
        return(
            <a href={url} className="select-none w-40 h-[60px] group flex flex-row transition-all duration-1000 relative font-jock">
                <h3 className='relative z-30 mx-auto my-auto text-2xl text-white font-bold drop-shadow-md group-active:drop-shadow-none group-active:top-2 group-active:left-1'>{ children }</h3>
                <img src={ buttonForeground } className="w-40 absolute z-20 top-0 left-0 group-active:top-2 group-active:left-1 drop-shadow-md" />
                <img src={ buttonBg } className="w-40 absolute top-2 left-1 drop-shadow-lg" />
                {/* <span className='text-sm z-50'>Warning: no URL or onClick provided</span> */}
            </a>
        )
    }
}

export function PrimaryButton({ children, url, clickEvent, type, size = 'small',  ...otherProps }) {
    switch (size) {
        case 'long': {
            const BgImg = type === 'primary' ? longButtonBg : longGreenButtonBg
            return <div 
                {...otherProps} 
                className={`relative w-full h-[56px] text-3xl mx-auto group flex items-center justify-center transition-all duration-1000 select-none font-jock ${otherProps.className}`}
                onClick={url ? () => window.open(url, '_blank') : clickEvent}
            >
                <div 
                    className={`relative w-full h-full bg-no-repeat bg-[length:100%_100%] z-30 flex items-center justify-center text-center -top-[0.2dvh] -left-[0.573dvh] group-active:top-[0.473dvh] group-active:left-0 text-white !button-label ${type === 'primary' ? '' : '!button-label-blue'}`}
                    style={{
                        backgroundImage: `url(${type === 'primary' ? `/images/button_top_long.png` : '/images/button_top_green_long.png'})`,
                    }}
                >{children}</div>
                <img src={BgImg} className='w-full h-full absolute top-0 left-[0.236dvh] select-none scale-[0.95]'/>
            </div>
        }
        case 'small': {
            const BgImg = type === 'primary' ? buttonBg : greenButtonBg
            return  <div
                {...otherProps}
                className={`relative h-[58px] text-3xl group flex items-center transition-all duration-1000 select-none font-jock ${otherProps.className || ''}`}
                onClick={url ? () => window.open(url, '_blank') : clickEvent}>
                <div 
                    className={`relative z-30 w-full h-full bg-no-repeat bg-[length:100%_100%] text-white top-0 -left-[0.4dvh] group-active:top-[0.45dvh] group-active:left-1 !button-label flex items-center justify-center ${type === 'primary' ? '' : '!button-label-blue'}`}
                    style={{
                        backgroundImage: `url(${type === 'primary' ? `/images/button_top.png` : '/images/button_top_green.png'})`,
                    }}
                >{children}</div>
                <img 
                    src={BgImg} 
                    className="w-full select-none h-full absolute left-1 drop-shadow-lg scale-90" 
                />
            </div>
        }
        case 'medium': {
            const BgImg = type === 'primary' ? buttonBg : greenButtonBg
            return  <div
                {...otherProps}
                className={`relative w-1/2 h-[58px] text-3xl group flex items-center transition-all duration-1000 select-none font-jock ${otherProps.className || ''}`}
                onClick={url ? () => window.open(url, '_blank') : clickEvent}>
                <div 
                    className={`relative z-30 w-full h-full bg-no-repeat bg-[length:100%_100%] text-white top-0 -left-[0.4dvh] group-active:top-[0.45dvh] group-active:left-1 !button-label flex items-center justify-center ${type === 'primary' ? '' : '!button-label-blue'}`}
                    style={{
                        backgroundImage: `url(${type === 'primary' ? `/images/button_top.png` : '/images/button_top_green.png'})`,
                    }}
                >{children}</div>
                <img 
                    src={BgImg} 
                    className="w-full select-none h-full absolute left-1 drop-shadow-lg scale-90" 
                />
            </div>
        }
        default: return null
    }
}
