import tap from '../img/tap to chip.svg'
import chip from '../img/chip.png'
import gameOver from '../img/game-over.svg'

export const GameStartBtn = ({ handleClick }) => {
  return (
    <img alt='start' src={tap} className='absolute z-10 w-[273px] h-auto top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]' onClick={handleClick} />
  )
}

export const GameChipBtn = ({ handleClick }) => {
  return (
    <img alt='chip' src={chip} className='absolute z-10 w-[120px] h-auto bottom-[8vh] right-[8vw]' onClick={handleClick} />
  )
}

export const GameOverTile = () => {
  return (
    <img alt='game over' src={gameOver} className='absolute z-10 w-[273px] h-auto top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]' />
  )
}