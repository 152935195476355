import { useState, useEffect, useRef } from 'react';
import winghausLogoRed from '../img/winghaus-full-logo.png';
import line from '../img/line.png';
import outline from '../img/outline.png';
import { PrimaryButton } from './buttons';
import { getLeaderboard, getSelfPosition } from '../api/leaderboard';
import useDetectDVH from '../hooks/useDetectDVH';

const Tabs = [
  {
    key: 'top',
    title: 'TOP SCORES'
  },
  {
    key: 'wooden',
    title: 'Wooden spoon'
  }
]

const Columns = ['Rank', 'Team', 'Location', 'Score']

export default function Leaderboard({ userData, handleInvite, handleContinue }) {
  const [activeTab, setActiveTab] = useState('top')
  const [dataSource, setDataSource] = useState([])
  const [selfInfo, setSelfInfo] = useState({
    rank: 25,
    location: 'NSW',
    score: 110
  })
  const [leaderboard, setLeaderboard] = useState([])
  const [woodenSpoon, setWoodenSpoon] = useState([])

  useDetectDVH()

  useEffect(() => {
    const getData = async () => {
      try {
        let res = await getLeaderboard({ size: 6, dir: 'ASC'})
        res = res.map(o => {
          return {
            id: o.id,
            team: o.player.teamName,
            rank: o.overallRank,
            location: o.player.location,
            score: o.playerMaxScore
          }
        })
        setLeaderboard(res)
        let wood = await getLeaderboard({ size: 6, dir: 'DESC'})
        wood = wood.map(o => {
          return {
            id: o.id,
            rank: o.overallRank,
            team: o.player.teamName,
            location: o.player.location,
            score: o.playerMaxScore
          }
        })
        setWoodenSpoon(wood)
        const self = await getSelfPosition(userData.id)
        setSelfInfo({
          id: self.id,
          rank: self.overallRank,
          team: self.player.teamName,
          location: self.player.location,
          score: self.playerMaxScore
        })
        if (activeTab === 'top') {
          setDataSource(res)
        } else {
          setDataSource(wood)
        }
      } catch (e) {
        console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
      }
    }

    if (userData.id) {
      getData()
    }
  }, [userData])

  useEffect(() => {
    if (activeTab === 'top') {
      setDataSource(leaderboard)
    } else {
      setDataSource(woodenSpoon)
    }
  }, [activeTab])

  return (
    <div id="page_leaderboard" className={`grassBg absolute top-0 left-0 z-[999] w-full h-[100dvh] flex flex-col items-center py-6 overflow-y-scroll`}>
      {/* Logo */}
      <img alt="Winghaus logo" src={winghausLogoRed} className='mx-auto w-[13vw]'/>
      {/* Title */}
      <h1 className='mt-[3.5vw] leading-none font-jock tracking-[0.9px] text-3xl'>LEADERBOARD</h1>
      {/* Leaderboard area */}
      <div className='w-full grid grid-cols-[45%,1fr] text-[5.14vw] mt-[3.5vw] font-jock leading-none'>
        {
          Tabs.map((tab) => (
            <div 
              key={tab.key} 
              className={`text-center col py-[3.856vw] tracking-[0.6px] leading-none ${activeTab === tab.key ? 'bg-black bg-opacity-40' : 'opacity-[63%]'}`}
              onClick={() => setActiveTab(tab.key)}
            >{ tab.title }</div>
          ))
        }        
      </div>
      <div className="w-full bg-black bg-opacity-40 text-[5.14vw]">
        <div className="w-full grid grid-cols-4 text-center font-bold leading-none">
          {
            Columns.map((col) => <div key={col} className="py-[3.856vw] col">{col}</div>)
          }
        </div>
        <div className='h-[77.112vw]'>
          {
            dataSource.map((item, index) => 
              <div 
                key={item.id} 
                className="w-full grid grid-cols-4 text-center py-[3.856vw] col font-bold leading-none"
              >
                <div>{index + 1}</div>
                <div className="truncate">{ item.team }</div>
                <div className="truncate">{ item.location }</div>
                <div className="truncate">{ item.score }</div>
              </div>
            )
          }
        </div>
        <div className='w-full mt-[2vw] line'>
          <img src={line} className="w-full" />
        </div>
        <div className='w-full mt-[2vw] relative outline-wrapper'>
          <img src={outline} className="w-full" />
          <div className="absolute w-full left-0 top-1/2 transform -translate-y-1/2 grid grid-cols-4 text-center font-bold text-[5.14vw]">
            <div>{ selfInfo.rank }</div>
            <div>You</div>
            <div>{ selfInfo.location }</div>
            <div>{ selfInfo.score }</div>
          </div>
        </div>
      </div>
      {/* FOOTER */}
      <div>
        <PrimaryButton 
          className="mt-[8vw] !w-[308.67px] mx-auto"
          size="long"
          type="primary"
          clickEvent={handleInvite}
        >Invite Friends</PrimaryButton>
        <PrimaryButton 
          className="mt-[4vw] !w-[230px] mx-auto"
          clickEvent={handleContinue}
        >
          Continue
        </PrimaryButton>
      </div>

    </div>
  )
}