/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { LoopRepeat, LoopOnce, MeshToonMaterial, Color } from 'three'
import { useFrame } from '@react-three/fiber'

const glb = "models/Chicken.glb";
export function Chicken(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(glb);
  const { actions, mixer } = useAnimations(animations, group);
  
  const jerseyTextures = useTexture([
    'textures/jerseys/jersy_test.jpg',
    'textures/jerseys/team_color_01.png',
    'textures/jerseys/team_color_02.png',
    'textures/jerseys/team_color_03.png',
    'textures/jerseys/team_color_04.png',
    'textures/jerseys/team_color_05.png',
    'textures/jerseys/team_color_06.png',
    'textures/jerseys/team_color_07.png',
    'textures/jerseys/team_color_08.png',
    'textures/jerseys/team_color_09.png',
    'textures/jerseys/team_color_10.png',
    'textures/jerseys/team_color_11.png',
    'textures/jerseys/team_color_12.png',
    'textures/jerseys/team_color_13.png',
    'textures/jerseys/team_color_14.png',
    'textures/jerseys/team_color_15.png',
    'textures/jerseys/team_color_16.png',
  ])
  jerseyTextures.forEach((texture) => {
    texture.flipY = false
  })

  const stopAllAnim = () => {
    Object.keys(actions).forEach((key) => {
      actions[key].stop().reset()
    })
  }

  useEffect(() => {
    if (actions.SprintBall && !props.isGameOver) {
      actions.SprintBall.setLoop(LoopRepeat, Infinity)
      actions.SprintBall.clampWhenFinished = true
      actions.SprintBall.setDuration(1.5)
      actions.SprintBall.reset().play()
    }
  }, [actions])

  useEffect(() => {
    const animationEnd = ({type, action}) => {
      if (!props.isGameOver) {
        if (action._clip.name === 'KickBall') {
          stopAllAnim()
          props.setThrowBall(true)
          actions.SprintNoBall.setLoop(LoopRepeat, Infinity)
          actions.SprintNoBall.clampWhenFinished = true
          actions.SprintNoBall.setDuration(1.5)
          actions.SprintNoBall.reset().play()
        } else if (action._clip.name === "DiveAnim") {
          stopAllAnim()
          props.setIsBallMoving(false)
          actions.SprintBall.setLoop(LoopRepeat, Infinity)
          actions.SprintBall.clampWhenFinished = true
          actions.SprintBall.setDuration(1.5)
          actions.SprintBall.reset().play()
          nodes["10525_Rugby_Ball_v1"].visible = true
        } else if (action._clip.name === "SprintFlinch") {
          stopAllAnim()
          props.setTackled(false)
          actions.SprintBall.setLoop(LoopRepeat, Infinity)
          actions.SprintBall.clampWhenFinished = true
          actions.SprintBall.setDuration(1.5)
          actions.SprintBall.reset().play()
        }
      }
    }
    if (mixer) {
      mixer.addEventListener('finished', animationEnd)
    }
    return () => {
      mixer.removeEventListener('finished', animationEnd)
    }
  }, [mixer, props.isGameOver])

  useEffect(() => {
    if (!props.isGameOver) {
      if (props.isBallMoving && !props.throwBall && actions.SprintBall && actions.KickBall && actions.SprintNoBall) {
        stopAllAnim()
        actions.KickBall.setLoop(LoopOnce)
        actions.KickBall.setDuration(0.5)
        actions.KickBall.reset().play()
        nodes["10525_Rugby_Ball_v1"].visible = false
      } else if (!props.isBallMoving && nodes["10525_Rugby_Ball_v1"].visible === false) {
        stopAllAnim()
        actions.SprintBall.setLoop(LoopRepeat, Infinity)
        actions.SprintBall.clampWhenFinished = true
        actions.SprintBall.setDuration(1.5)
        actions.SprintBall.reset().play()
        nodes["10525_Rugby_Ball_v1"].visible = true
      }
    }
  }, [props.isBallMoving])

  useEffect(() => {
    if (!props.isGameOver) {
      if (props.isBallMoving && !props.throwBall && nodes["10525_Rugby_Ball_v1"].visible === false) {
        if (actions.DiveAnim) {
          stopAllAnim()
          actions.DiveAnim.setLoop(LoopOnce)
          actions.DiveAnim.setDuration(0.5)
          actions.DiveAnim.reset().play()
        } else {
          stopAllAnim()
          props.setIsBallMoving(false)
          actions.SprintBall.setLoop(LoopRepeat, Infinity)
          actions.SprintBall.clampWhenFinished = true
          actions.SprintBall.setDuration(1.5)
          actions.SprintBall.reset().play()
          nodes["10525_Rugby_Ball_v1"].visible = true
        }
      }
    }
  }, [props.throwBall])

  useEffect(() => {
    if (props.tackled && actions && actions.SprintFlinch) {
      stopAllAnim()
      actions.SprintFlinch.setLoop(LoopOnce)
      actions.SprintFlinch.clampWhenFinished = true
      actions.SprintFlinch.setDuration(1.5)
      actions.SprintFlinch.reset().play()
    }
  }, [props.tackled])

  useEffect(() => {
    if (props.isGameOver) {
      stopAllAnim()
    } else if (actions && actions.SprintBall) {
      actions.SprintBall.setLoop(LoopRepeat, Infinity)
      actions.SprintBall.clampWhenFinished = true
      actions.SprintBall.setDuration(1.5)
      actions.SprintBall.reset().play()
    }
  }, [props.isGameOver])

  // flash invincibility
  const flashRef = useRef()

  useFrame((state, delta) => {
    if (mixer) {
      mixer.update(delta)
    }

    if (flashRef.current && props.invincible) {
      const flashing = Math.abs(Math.sin(5 * state.clock.elapsedTime))
      const color1 = new Color(0xff0000);
      const color2 = new Color(0xffffff);
      const final = new Color().lerpColors(color1, color2, flashing)
      flashRef.current.material.color = final
    } else {
      flashRef.current.material.map = materials.BaseTexture.map
    }
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig_jago" position={[0, 2.427, 0.083]}>
          <group name="Chicken">
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago"
              geometry={nodes.jago.geometry}
              material={new MeshToonMaterial({ color: "#ff0000" })}
              skeleton={nodes.jago.skeleton}
            />
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago_1"
              geometry={nodes.jago_1.geometry}
              material={new MeshToonMaterial({ map: props.selectedJersey !== null ? jerseyTextures[props.selectedJersey + 1] : jerseyTextures[0] })}
              skeleton={nodes.jago_1.skeleton}
            />
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago_2"
              geometry={nodes.jago_2.geometry}
              material={new MeshToonMaterial({ color: "black" })}
              skeleton={nodes.jago_2.skeleton}
            />
            <skinnedMesh
              ref={flashRef}
              castShadow
              receiveShadow
              name="jago_3"
              geometry={nodes.jago_3.geometry}
              material={new MeshToonMaterial({ map: materials.BaseTexture.map })}
              skeleton={nodes.jago_3.skeleton}
            />
            <skinnedMesh
              castShadow
              receiveShadow
              name="jago_4"
              geometry={nodes.jago_4.geometry}
              material={new MeshToonMaterial({ color: "brown" })}
              skeleton={nodes.jago_4.skeleton}
            />
          </group>
          <primitive object={nodes.root} castShadow receiveShadow />
          <primitive object={nodes.pinggang} castShadow receiveShadow />
          <primitive object={nodes["ik-kakiL"]} castShadow receiveShadow />
          <primitive object={nodes["ik-kakiR"]} castShadow receiveShadow />
        </group>
        <group name="Empty" position={[4, 0, 2]}>
          <group name="giz_jari" />
          <group name="giz_kaki" />
          <group name="giz_main" />
          <group name="giz_root" scale={0.553} />
          <group name="giz_tangan" />
          <group name="giz_tulang" />
          <mesh
            name="giz_tumit"
            castShadow
            receiveShadow
            geometry={nodes.giz_tumit.geometry}
            material={nodes.giz_tumit.material}
          />
          <group name="giz_wajah" />
        </group>
        <group name="WorldOrigin" />
      </group>
    </group>
  );
}

useGLTF.preload(glb);