import service from "./service";

export async function createGameSession(data) {
  const res = await service.post('/game-sessions', {
    data: data
  });
  return res.data
}

export async function updateGameSession(id, data) {
  try {
    const res = await service.put(`/game-sessions/${id}`, {
      data: data
    });
    return res.data
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}

export async function createGameSessionEvent(data) {
  try {
    const res = await service.post('/game-session-events', {
      data: data
    });
    return res.data
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}