import service from "./service";

export async function drawPrize(data) {
  try {
    const res = await service.post('/prize/draw-luck-prize', {
      data: data
    });
    return res.data
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}

export async function claimReward(id) {
  try {
    const res = await service.patch(`/prize/claim-prize/${id}`);
    return res.data
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}

export async function getPrize(id) {
  try {
    const res = await service.get(`/prize/encrypt-prize/${id}`);
    return res.data
  } catch (e) {
    console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
  }
}