// Hook imports
import { useState, useEffect, useRef } from "react";
import { useThree, useFrame } from "@react-three/fiber";

// Local imports
import { Chicken } from '../models/Chicken';
import { Rugby } from '../models/Rugby';
import { getSpeed, pointSettings, laneWidth } from "../utils";

function lerp(start, end, t) {
  return start * (1 - t) + end * t;
}

export function Player({ setMetresGained, obstaclesTrack1, obstaclesTrack2, playerLane, 
  pickupsTrack1, pickupsTrack2, setTackledAt, tackledAt, metresGained,
  ballLane, isBallMoving, setIsBallMoving, isGameOver, sendGameEvent, trackSpeed, setTrackSpeed, 
  setBeers, setWings, setChickens, setLives, setCollected, collected, invincible, setInvincible,
  tackled, setTackled, setShowChip, selectedJersey }) {
  const [isVisible, setIsVisible] = useState(true);
  const [throwBall, setThrowBall] = useState(false);
  const collided = useRef()
  const clockRef = useRef()
  const ballRef = useRef();

  const { viewport } = useThree();
  const playerZPosition = -(viewport.height / 2) + 15; // Adjust to keep player at bottom
  const posX = useRef(playerLane * laneWidth);

  useEffect(() => {
    if (!isBallMoving) {
      clockRef.current = null;
      ballRef.current.position.set([0,0,0])
    }
  }, [isBallMoving])

  useFrame(({ clock }) => {
    if (!isGameOver) {
      // Update metres
      setMetresGained(prevMetres => trackSpeed > 0 ? prevMetres + trackSpeed / 100 + Math.random() / 10 : prevMetres);

      // Move lanes
      if (posX.current !== playerLane * laneWidth) {
        // move gradually to new lane
        posX.current = lerp(posX.current, playerLane * laneWidth, 0.2);
      }

      // Handle obstacles
      const obstaclesList = obstaclesTrack1.concat(obstaclesTrack2).concat(pickupsTrack1).concat(pickupsTrack2);
      // Check if upcoming items has 3 obstacles in a row
      const tempList = [] // List of upcoming obstacles grouped by distance apart
      obstaclesList.filter(x => x[7] === 'obstacle').forEach(o => {
        const tempItem = tempList.find(x => x.find(y => Math.abs(y[4] - o[4]) < 50))
        if (tempItem) {
          tempItem.push(o)
        } else {
          tempList.push([o])
        }
      })
      const upcomingObstacles = tempList.find(x => x.length > 2 && new Set(x.map(o => o[0])).size === 3 && x.every(o => o[4] < playerZPosition && o[4] > playerZPosition - 200))
      if (upcomingObstacles) {
        setShowChip(true)
      } else {
        setShowChip(false)
      }
      
      // Check collision
      const threshold = -20;
      obstaclesList.forEach(obstacle => {
        // console.log('Obstacle distance from player', obstacle[4] - playerZPosition);
        //obstacle[3] == lane
        //obstacle[4] == distance down track
        //obstacle[5] == height
        if (
          (Math.abs(obstacle[0] - (playerLane * laneWidth)) === 0) && trackSpeed > 0 &&
          (obstacle[4] - playerZPosition > threshold) && (obstacle[4] < playerZPosition) && collided.current !== obstacle[6] && !collected.includes(obstacle[6])) {
          // console.log(obstacle, [playerLane * laneWidth, 0, playerZPosition])    
          // console.log('COLLISION')
          collided.current = obstacle[6];
          if (obstacle[7] === 'obstacle' && !isBallMoving && !invincible && (!tackledAt || (tackledAt.obstacle && tackledAt.obstacle !== obstacle[6]))) {
            setTackled(true)
            setTackledAt({lane: playerLane, obstacle: obstacle[6]});
            setTrackSpeed(0.0);
            setLives(prevLives => prevLives - 1);
            setInvincible(true)
            
            // let flashCount = 0;
            // const interval = setInterval(() => {
            //   //setIsVisible(v => !v);
            //   flashCount++;
            //   if (flashCount === 1) clearInterval(interval); // 4 flashes (on-off-on-off)
            // }, 100); // Adjust timing for flash effect
            setTimeout(() => {
              setTrackSpeed(getSpeed(metresGained))
              setTimeout(() => {
                setInvincible(false)
              }, 2000)
              setTimeout(() => {
                if (tackled) setTackled(false)
                collided.current = null;
              }, 500)
            }, 1500); // Reset speed after 2 seconds
          } else if (obstacle[7] === 'beer') {
            setBeers(prevBeers => prevBeers + 1);
            // sendGameEvent({ eventType: 'beer', point: pointSettings.beer });
            setCollected(prevCollected => [...prevCollected, obstacle[6]]);
            setTimeout(() => {
              collided.current = null;
            }, 200)
          } else if (obstacle[7] === 'chickenWings') {
            setWings(prevWings => prevWings + 1);
            // sendGameEvent({ eventType: 'chickenWings', point: pointSettings.chickenWings });
            setCollected(prevCollected => [...prevCollected, obstacle[6]]);
            setTimeout(() => {
              collided.current = null;
            }, 200)
          } else if (obstacle[7] === 'largeChickenWings') {
            setChickens(prevChickens => prevChickens + 1);
            // sendGameEvent({ eventType: 'largeChickenWings', point: pointSettings.largeChickenWings });
            setCollected(prevCollected => [...prevCollected, obstacle[6]]);
            setTimeout(() => {
              collided.current = null;
            }, 200)
          }
        }
      });
    }

    if (isBallMoving) {
      if (!clockRef.current) {
        clockRef.current = clock.getElapsedTime();
      }
      const time = clock.getElapsedTime() - clockRef.current + 0.125;
      const height = 40; // Maximum height
      const speed = 2; // Speed of ascent and descent

      // Calculate the new Y position of the ball
      // let newY = Math.sin(time * speed) * height + height - 1;
      let newY = Math.abs(Math.sin(time * speed)) * height;
      if (ballRef.current && ballRef.current.position.y > newY + 12.5 && newY < 10) {
        setThrowBall(false);
      }
      if (ballRef.current) {
        ballRef.current.position.set(ballLane * laneWidth, newY + 12.5, playerZPosition - 6.5);
        ballRef.current.rotation.x += 0.1;
      }

      // Stop the ball when it returns to the starting position
      if (newY < 1) {
        setIsBallMoving(false);
      }
    }
  });

  return (
    <group visible={isVisible}>
      <Chicken scale={1.8} rotation={[0, Math.PI , 0]} 
        position={[posX.current, 0, playerZPosition]} 
        selectedJersey={selectedJersey} invincible={invincible}
        isGameOver={isGameOver} tackled={tackled} setTackled={setTackled}
        isBallMoving={isBallMoving} throwBall={throwBall} setThrowBall={setThrowBall}
        setIsBallMoving={setIsBallMoving} />
      <Rugby ballRef={ballRef} scale={2} castShadow receiveShadow visible={isBallMoving} />
    </group>
  );
}