// Hook imports
import React, { useState, useEffect, useRef, Suspense, useMemo } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Plane, useTexture, Sky, Preload, Html } from '@react-three/drei';
import { useParams, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

// Component imports
import { Player } from './components/player';
import { Track1, Track2 } from './components/tracks';
import SoundPlayer, { BackgroundMusic, MenuMusic } from './components/sound_player';
import { GameScoreElement } from './components/game_score_element';
import MainMenu from './components/main_menu';
import SignUpPage from './components/sign_up_page';
import TeamColours from './components/team_colours';
import LeaderboardPage from './components/leaderboard_page';
import InvitePage from './components/invite_page'
import BackPage from './components/back_page'
import { GameChipBtn, GameOverTile, GameStartBtn } from './components/game_btn';
import GameoverPage from './components/gameover_page';
import BetterLuckPage from './components/better_luck_page';
import HowToPlayPage from './components/how_to_play';

// Asset imports
import kickBall from './img/game effects/kick-ball.gif';
import catchBall from './img/game effects/catch-ball-narrow_1.gif'
import collect from './img/game effects/collect-item.gif'
import dash from './img/game effects/dash-anim.gif'
import tackle from './img/game effects/tackled.gif'
import { bumpPath, coinPath, dashPath, itemPath, generateObstacles, generatePickups, jerseyReference, pointSettings, getSpeed, laneWidth, whistlePath } from './utils';

// API
import { createGameSession, updateGameSession, createGameSessionEvent } from './api/game_session';
import { drawPrize, getPrize } from './api/prize';
import { Counter } from './components/counter';
import MobileOnly from './components/mobile_only'; 

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function Stadium () {
  const background = useTexture('textures/background-stadium.jpg');
  return (
    <Plane position={[0, 140, -1000]} rotation={[-0.2, 0, 0]} args={[3000, 500, 100, 10]}>
      <meshBasicMaterial map={background} />
    </Plane>
  )
}

function GifAnim({ className, crunched, pickUp, isBallMoving, dashFrom, tackledAt, gifPos}) {
  const [showKick, setShowKick] = useState(false)
  const [showTackled, setShowTackled] = useState(false)
  const [showCatch, setShowCatch] = useState(false)
  const [showDash, setShowDash] = useState(false)

  useEffect(() => {
    if (isBallMoving) {
      setShowKick(true)
      setTimeout(() => {
        setShowKick(false)
      }, 350)
    }
  }, [isBallMoving])

  useEffect(() => {
    if (crunched) {
      setShowCatch(true)
      setTimeout(() => {
        setShowCatch(false)
      }, 450)
    }
  }, [crunched])

  useEffect(() => {
    if (tackledAt) {
      setShowTackled(true)
      setTimeout(() => {
        setShowTackled(false)
      }, 350)
    }
  }, [tackledAt])

  useEffect(() => {
    if (dashFrom) {
      setShowDash(true)
      setTimeout(() => {
        setShowDash(false)
      }, 400)
    }
  }, [dashFrom])

  const dashPos = useMemo(() => {
    if (dashFrom) {
      // if (dashFrom.lane < 0) {
      //   return -2 * laneWidth
      // } else if (dashFrom.lane === 0) {
      //   return 0
      // } else if (dashFrom.lane > 0) {
      //   return 2 * laneWidth
      // }
      return dashFrom.lane * laneWidth
    } else return 0
  }, [dashFrom])

  const tacklePos = useMemo(() => {
    if (tackledAt) {
      // if (tackledAt.lane < 0) {
      //   return -2 * laneWidth
      // } else if (tackledAt.lane === 0) {
      //   return 0
      // } else if (tackledAt.lane > 0) {
      //   return 2 * laneWidth
      // }
      return tackledAt.lane * laneWidth;
    } else return 0
  }, [tackledAt])


  const { viewport } = useThree();
  return (
    <>
      <Html
        position={[viewport.width/2.37 + tacklePos, 0, -22.2]}
        center
        fullscreen>
          <img hidden={!showTackled} alt='tackled' src={tackle} className={className} />
      </Html>
      <Html
        position={[viewport.width/2.37 + gifPos, 0, -22.2]}
        center
        fullscreen>
        <img hidden={!showCatch} alt='catch ball' src={catchBall} className={className} />
      </Html>
      <Html
        position={[viewport.width/2.37 + gifPos, 0, -22.2]}
        center
        fullscreen>
        <img hidden={!pickUp} alt='pick up' src={collect} className={className} />
      </Html>
      <Html
        position={[viewport.width/2.37 + gifPos, 0, -22.2]}
        center
        fullscreen>
        <img hidden={!showKick} alt='kick' src={kickBall} className={className} />
      </Html>
      <Html
        position={[viewport.width/2.37 + dashPos, 0, -22.2]}
        center
        fullscreen>
        <img hidden={!showDash} alt='dash' src={dash} className={`fixed z-10 w-[260px] h-auto bottom-[8vh] lane-middle translate-x-[-50%] ${dashFrom && dashFrom.dir === 'right' && 'scale-x-[-1]'}`} />
      </Html>
    </>
  )
}

function Game() {
  // Game settings
  const [isGameOver, setIsGameOver] = useState(true); // Track if the game is over
  const [trackSpeed, setTrackSpeed] = useState(0.0); // Initialize trackSpeed
  const [lives, setLives] = useState(3); // Initialize lives 
  const [lane, setLane] = useState(0); // -1: left, 0: middle, 1: right
  const [ballLane, setBallLane] = useState(0);
  const [showChip, setShowChip] = useState(false); // Whether to show 'chip' button
  const [soundPath, setSoundPath] = useState(bumpPath);
  const [volume, setVolume] = useState(0);
  const [gifLane, setGifLane] = useState(0);
  const [obstaclesTrack1, setObstaclesTrack1] = useState(generateObstacles(0));
  const [obstaclesTrack2, setObstaclesTrack2] = useState(generateObstacles(0));
  const [pickupsTrack1, setPickupsTrack1] = useState(generatePickups(0));
  const [pickupsTrack2, setPickupsTrack2] = useState(generatePickups(0));
  const [dashFrom, setDashFrom] = useState(null);
  const [tackledAt, setTackledAt] = useState(null);
  const [invincible, setInvincible] = useState(false);

  // Detect when game ends
  useEffect(() => {
    if (lives <= 0) {
      setIsGameOver(true);
      setTrackSpeed(0);
      sendGameEvent({eventType: 'gameOver', point: Math.floor(Math.floor(metresGained) / 30) * pointSettings.normal})
      setTimeout(() => {
        updateGameSession(gameSession.id, { status: 'played', totalScore: points, completedAt: new Date() }).then(res => {
          setGameSession(res.data)
          drawPrize({ gameSessionId: gameSession.id }).then(res => {
            if (res.isLuck) {
              setPrize(res.prize)
            }
            setStep('gameEnd')
            setTimeout(() => {
              if (res.isLuck) {
                setStep('gameOver')
              } else setStep('betterLuck')
            }, 3000)
          })
        })
      }, 500)
    }
  }, [lives])

  const startGame = () => {
    setSoundPath(whistlePath)
    setVolume(1)
    setShowCounter(false);
    setLives(3);
    setChipsCaught(0);
    setMetresGained(0);
    setTackled(false);
    setIsGameOver(false)
    setTrackSpeed(getSpeed(0))
  }
  
  // Scores
  const [metresGained, setMetresGained] = useState(0.0); // Initialize metresGained
  const [chipsCaught, setChipsCaught] = useState(0); // Initialize chipsCaught
  const [beers, setBeers] = useState(0); // Initialize beers
  const [wings, setWings] = useState(0); // Initialize wings
  const [chickens, setChickens] = useState(0); // Initialize chickens
  // const [triesScored, setTriesScored] = useState(0); // Initialize triesScored

  const points = useMemo(() => {
    return chipsCaught * pointSettings.ballCatch + Math.floor(Math.floor(metresGained) / 30) * pointSettings.normal + beers * pointSettings.beer + wings * pointSettings.chickenWings + chickens * pointSettings.largeChickenWings;
  }, [chipsCaught, metresGained, beers, wings, chickens]);

  // Indicators
  const [collected, setCollected] = useState([]);
  const [tackled, setTackled] = useState(false);
  const [crunched, setCrunched] = useState(false);
  const [pickUp, setPickUp] = useState(false);
  const [isBallMoving, setIsBallMoving] = useState(false);
  const prevBallMoving = useRef(isBallMoving);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimer(prevTimer => {
  //       if (prevTimer === 1) {
  //         setIsGameOver(true); // Set game over state
  //         // Additional logic to handle game over
  //         gameOver = true;
  //         clearInterval(interval)
  //         return 0; // Set timer to 0
  //       }
  //       return prevTimer - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  // Game control
  const touchStart = useRef(null);

  const handleTouchStart = (event) => {
    touchStart.current = {
      x: event.touches[0].clientX,
      y: event.touches[0].clientY
    };
  };
  const handleTouchEnd = (event) => {
    const touchEnd = {
      x: event.changedTouches[0].clientX,
      y: event.changedTouches[0].clientY
    };
    const xDiff = touchEnd.x - touchStart.current.x;
    const yDiff = touchEnd.y - touchStart.current.y;
    if (Math.abs(yDiff) > Math.abs(xDiff) && yDiff < 0 && !isGameOver) {
      // Upward swipe
      handleChip()
    } else if (xDiff > 0 && !isGameOver) {
      // Right swipe
      setLane(prevLane => {
        setDashFrom({
          lane: prevLane,
          dir: 'right'
        })
        if (prevLane < 2) {
          return prevLane + 2;
        }
        return prevLane;
      })
    } else if (xDiff < 0 && !isGameOver) {
      // Left swipe
      setLane(prevLane => {
        setDashFrom({
          lane: prevLane,
          dir: 'left'
        })
        if (prevLane > -2) {
          return prevLane - 2;
        }
        return prevLane;
      })
    }
  }

  const handleChip = () => {
    if (!isGameOver && !isBallMoving) {
      setBallLane(lane)
      setIsBallMoving(true);
    }
  }

   // Detect if a chipped ball is caught
   useEffect(() => {
    if (!isBallMoving && prevBallMoving.current !== isBallMoving && !isGameOver && ballLane === lane) {
      setChipsCaught(prevChipsCaught => prevChipsCaught + 1);
      setCrunched(true)
      setTimeout(() => setCrunched(false), 300)
      // sendGameEvent({ eventType: 'ballCatch', point: pointSettings.ballCatch })
    } else if (!isBallMoving && prevBallMoving.current !== isBallMoving && !isGameOver && ballLane !== lane) {
      setLives(prev => prev - 1)
      // sendGameEvent({ eventType: 'ballMiss', point: 0 })
      setTackled(true)
      setInvincible(true)
      setTrackSpeed(0)
      setTimeout(() => {
        setTackled(false)
        setTrackSpeed(getSpeed(metresGained))
        setTimeout(() => {
          setInvincible(false)
        }, 2000)
      }, 1000)
    }
    setBallLane(lane)
    prevBallMoving.current = isBallMoving
  }, [isBallMoving])

  // track speed gradual start
  useEffect(() => {
    if (trackSpeed > 0 && metresGained < 50) {
      const speed = getSpeed(metresGained)
      if (trackSpeed !== speed) setTrackSpeed(speed)
    }
  }, [metresGained, trackSpeed])

  // Control gif animation lane
  const gifPos = useMemo(() => {
    if (gifLane < 0) {
      return -2 * laneWidth
    } else if (gifLane === 0) {
      return 0
    } else if (gifLane > 0) {
      return 2 * laneWidth
    } else return 0
  }, [gifLane])

  // Sound effect controls
  useEffect(() => {
    if (tackled && (soundPath !== bumpPath || volume !== 1)) {
      setSoundPath(bumpPath)
      setVolume(1)
    }
  }, [tackled])

  useEffect(() => {
    if (crunched && (soundPath !== coinPath || volume !== 1)) {
      setSoundPath(coinPath)
      setVolume(1)
    }
  }, [crunched])

  useEffect(() => {
    if (dashFrom && (soundPath !== dashPath || volume !== 1)) {
      setSoundPath(dashPath)
      setVolume(1)
    }
  }, [dashFrom])

  useEffect(() => {
    if (pickUp && (soundPath !== itemPath || volume !== 1)) {
      setSoundPath(itemPath)
      setVolume(1)
    }
  }, [pickUp])

  // GIF animation controls
  useEffect(() => {
    if (gifLane !== lane) {
      setGifLane(lane)
    }
  }, [lane])

  useEffect(() => {
    setPickUp(true)
    setTimeout(() => setPickUp(false), 300)
  }, [collected.length])

  // Form elements & player info
  const [step, setStep] = useState('home');
  const [userData, setUserData] = useState({
    team_name: '', 
    full_name: '', 
    email_address: '', 
    mobile_number: '', 
    marketingOptIn: false, 
    termsOptIn: false,
    state: 'NSW'
  })
  const [ selectedJersey, setSelectedJersey] = useState(0);
  const [gameSession, setGameSession] = useState();
  const [prize, setPrize] = useState();
  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [countDown, setCountDown] = useState(0);

  // Game data for BE
  async function startGameSession() {
    try {
      const res = await createGameSession({
        name: userData.attributes.teamName,
        skin: jerseyReference[selectedJersey].jersey,
        status: 'waiting',
        playerId: { connect: [userData.id] }
      })
      setGameSession(res.data)
      setStep('game')
    } catch (e) {
      console.error(e.response.data ? e.response.data.error ? e.response.data.error.message : e : e);
      if (e.response && e.response.data && e.response.data.error && e.response.data.error.message === 'Already played today') {
        setAlreadyPlayed(true)
        setStep('gameOver')
      }
    }
  }

  async function handleStart() {
    if (lives === 0 && metresGained > 0) {
      window.location.reload()
    }
    const res = await updateGameSession(gameSession.id, { status: 'playing', startedAt: new Date() })
    setGameSession(res.data)
    setCountDown(3);
    setShowCounter(true);
  }

  async function sendGameEvent(data) {
    await createGameSessionEvent({
      ...data,
      gameData: data.gameData || {lives, metresGained, chipsCaught, beers, wings, chickens},
      gameSessionId: { connect: [gameSession.id.toString()] }
    })
  }

  // Google analytics tracking
  useEffect(() => {
    if (step) {
      ReactGA.send({ hitType: 'pageview', page: step, title: step.slice(0, 1).toUpperCase() + step.slice(1) })
    }
  }, [step])

  return (
    <>
      {/* OVERLAY PAGES */}
      {step === 'home' && <MainMenu handlePlay={() => {setStep('register')}} />}
      {step === 'register' && <SignUpPage userData={userData} setUserData={setUserData} setStep={setStep} />}
      {step === 'jersey' && <TeamColours selectedJersey={selectedJersey} setSelectedJersey={setSelectedJersey} onSubmit={() => setStep('guide')} />}
      {step === 'guide' && <HowToPlayPage onSubmit={startGameSession}/>}

      {step === 'gameOver' && <GameoverPage prize={prize} score={gameSession?.attributes?.totalScore || points} toLeaderboard={() => setStep('leaderboard')} alreadyPlayed={alreadyPlayed} onBack={() => setStep('backpage')}  />}
      {step === 'betterLuck' && <BetterLuckPage toLeaderboard={() => setStep('leaderboard')} score={gameSession?.attributes?.totalScore || points} />}
      {step === 'leaderboard' && <LeaderboardPage userData={userData} handleInvite={() => setStep('invite')} handleContinue={() => setStep('backpage')} />}
      {step === 'invite' && <InvitePage inviterId={userData.id} returnToLeaderboard={() => setStep('leaderboard')} />}
      {step === 'backpage' && <BackPage />}
      {step !== 'game' && step !== 'gameEnd' && (
        <div className='grassBg absolute top-0 left-0 z-[800] w-full h-[100dvh]'>
        </div>
      )}

      { step !== 'game' && <MenuMusic />}

      {showCounter && <Counter countDown={countDown} handleComplete={startGame} />}
      {/* NOTE: example of the score/timer UI element */}
      {step === 'game' && <GameScoreElement score={points} distance={Math.floor(metresGained)} lives={lives} />}
      {step === 'gameEnd' && <GameOverTile />}
      {!isGameOver && (
        <>
          <SoundPlayer soundPath={soundPath} volume={volume} setVolume={setVolume} />
          <BackgroundMusic />
        </>
      )}
      {isGameOver && !showCounter && lives > 0 && (<GameStartBtn handleClick={handleStart} />)}
      {!isGameOver && showChip && (<GameChipBtn handleClick={handleChip} />)}
      <Canvas camera={{ position: [0, 60, 5], rotation: [-Math.PI / 7, 0, 0], fov: 80 }} shadows="true" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <hemisphereLight intensity={0.6} />
        <directionalLight
          // ref={lightRef}
          position={[35, 27, 0]}
          intensity={1.5}
          castShadow={true}
          shadowBias={-0.00001}
          shadow-camera-near={0.1}
          shadow-camera-far={200}
          shadow-camera-left={-100}
          shadow-camera-right={700}
          shadow-camera-top={200}
          shadow-camera-bottom={-200}
        />

        <Suspense fallback={null}>
          {!isGameOver && (<GifAnim crunched={crunched} pickUp={pickUp} gifPos={gifPos}
            isBallMoving={isBallMoving} dashFrom={dashFrom} tackledAt={tackledAt}
            className={`fixed z-10 w-[260px] h-auto bottom-[8vh] lane-middle translate-x-[-50%]`} />)}
          <Player setLives={setLives} isGameOver={isGameOver} 
          collected={collected} setCollected={setCollected} setBeers={setBeers} 
          setWings={setWings} setChickens={setChickens} sendGameEvent={sendGameEvent}
          trackSpeed={trackSpeed} setTrackSpeed={setTrackSpeed} metresGained={metresGained}
          playerLane={lane} ballLane={ballLane} tackledAt={tackledAt}
          isBallMoving={isBallMoving} setIsBallMoving={setIsBallMoving} 
          setMetresGained={setMetresGained} setIsGameOver={setIsGameOver} 
          showChip={showChip} setShowChip={setShowChip} setTackledAt={setTackledAt}
          tackled={tackled} setTackled={setTackled} invincible={invincible} setInvincible={setInvincible}
          obstaclesTrack1={obstaclesTrack1} obstaclesTrack2={obstaclesTrack2}
          pickupsTrack1={pickupsTrack1} pickupsTrack2={pickupsTrack2}
          selectedJersey={selectedJersey} />
          <Preload all />
        </Suspense>

        <Stadium />

        <Sky castShadow={true} distance={200} sunPosition={[1, 10, 0]} />

        <Track1 isGameOver={isGameOver} collected={collected} trackSpeed={trackSpeed} 
        obstaclesTrack1={obstaclesTrack1} setObstaclesTrack1={setObstaclesTrack1}
        pickupsTrack1={pickupsTrack1} setPickupsTrack1={setPickupsTrack1} metresGained={metresGained} />
        <Track2 isGameOver={isGameOver} collected={collected} trackSpeed={trackSpeed}
        obstaclesTrack2={obstaclesTrack2} setObstaclesTrack2={setObstaclesTrack2}
        pickupsTrack2={pickupsTrack2} setPickupsTrack2={setPickupsTrack2} metresGained={metresGained} />
      </Canvas>
    </>
  );
}

function PrizeRedeem() {
  const params = useParams();

  const [page, setPage] = useState('prize');
  const [prize, setPrize] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchPrizeData = async(id) => {
      const data = await getPrize(id)
      setPrize({...data, encryptedId: id})
      setUserData(data.winner)
    }
    if (params && params.prizeId) {
      fetchPrizeData(params.prizeId)
    }
  }, [params])

  return(
    <>
      {page === 'leaderboard' && <LeaderboardPage userData={userData} handleInvite={() => setPage('invite')} handleContinue={() => setPage('home')} />}
      {page === 'invite' && <InvitePage inviterId={userData.id} returnToLeaderboard={() => setPage('leaderboard')} />}
      {page === 'backpage' && <BackPage onTimeout={() => setPage('leaderboard')} />}
      {page === 'prize' && <GameoverPage prize={prize} toLeaderboard={() => setPage('leaderboard')} onBack={() => setPage('backpage')} score={prize?.gameSession?.totalScore || 0} />}
    </>
  )
}

function App() {

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    handleResize();
    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, [])

  const location = useLocation();
  useEffect(() => {
    if (location.pathname + location.search !== '/') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: location.pathname.includes('prize') ? 'Prize' : 'Winghaus Chick & Chase' });
    }
  }, [location])
  
  return(
    <Routes>
      <Route exact path="/" element={isMobile ? <Game /> : <MobileOnly />} />
      <Route path="/prize/:prizeId" element={isMobile ? <PrizeRedeem /> : <MobileOnly />} />
    </Routes>
  )
}

export default App;
