/* 
 * Team colours: once the sign up form has been completed, players use this page to select their team colours
 * the selected colours will then load the corresponding texture that the chicken will wear in the game
 * Note: Background image is placeholder as the intention is the interface will animate, while the background remains static between 'pages'
 */
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from 'react';
import { PrimaryButton } from './buttons';
import useDetectDVH from "../hooks/useDetectDVH";

// Images
import winghausLogoTransparent from '../img/winghaus-watermark.svg';
import selectedJerseyIcon from '../img/tick.svg'
import arrowTop from '../img/arrow-top.svg'
import arrowBottom from '../img/arrow-bottom.svg'

// Utils
import { jerseyReference } from '../utils';

export default function TeamColours({ selectedJersey, setSelectedJersey, onSubmit }) {
    const [startX, setStartX] = useState(null);
    const [right, setRight] = useState(-8)
    const [ pageCounter, setPageCounter] = useState(0);
    const [ animDirection, setAnimDirection] = useState(1); // if back button is hit, this is -1 to make it animate from left to right rather than right to left

    useDetectDVH()

    const incPage = () => {
        if(pageCounter < 3) {
            setPageCounter(pageCounter + 1)
            setAnimDirection(1)
        }
    }

    const decPage = () => {
        if(pageCounter > 0) {
            setPageCounter(pageCounter - 1)
            setAnimDirection(-1)
        }
    }

    function whiteCircle(opacity) {
        return(
            <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" className={` transition-opacity duration-100 ${opacity === 1 ? "opacity-100" : "opacity-50"}`}>
                <circle cx="5" cy="5" r="5" fill="white" />
            </svg>
        )
    }

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
      };
    
    const handleTouchMove = (e) => {
        if (startX !== null) {
            const currentX = e.touches[0].clientX;
            const diffX = startX - currentX;

            if (diffX > 0) {
                incPage()
            }
            else if (diffX < 0) {
                decPage()
            }

            setStartX(null);
        }
    };

    useEffect(() => {
        const wrapper = document.querySelector('.color-img-wrapper')
        const img = wrapper.querySelector('img')
        const margin = wrapper.offsetWidth - img.offsetWidth
        setRight(margin / 2 - 8)
    }, [])

    return(<>
        <div className='grassBg absolute top-0 left-0 z-[999] w-full h-[100dvh] px-4 py-6 overflow-y-scroll'>
            <div>
            <h1 className='mb-1 text-3xl'>Select Team Colours</h1>
            {/* <p className='intro'>Please input your details below. Your team name will be visible on the leaderboard.</p> */}

            {/* Image carousel container */}
            <div className='pt-8'>
                {/* Jersy Images */}
                    <RenderJerseys idx={pageCounter} selectJersey={setSelectedJersey} currentJersey={selectedJersey} direction={animDirection} right={right} handleTouchStart={handleTouchStart} handleTouchMove={handleTouchMove}/>

                    {/* navigation */}
                    <div className='flex flex-row place-content-center mt-8 align-middle h-11'>
                        <button className={`relative w-14 group ${pageCounter == 0 ? 'opacity-50' : 'opacity-100'}`} onClick={decPage}>
                            <img src={arrowBottom} className='absolute left-3 top-1 drop-shadow-xl rotate-180' alt="arrow bottom"/>
                            <img src={arrowTop} className='absolute left-0.5 top-0 group-active:top-0 group-active:left-1 drop-shadow-md rotate-180' alt="arrow top"/>
                        </button>
                        <div className='flex flex-row space-x-1.5 mx-8 pt-2 my-auto'>
                            { whiteCircle(pageCounter == 0 ? 1 : 0.5) }
                            { whiteCircle(pageCounter == 1 ? 1 : 0.5) }
                            { whiteCircle(pageCounter == 2 ? 1 : 0.5) }
                            { whiteCircle(pageCounter == 3 ? 1 : 0.5) }
                        </div>
                        <button className={`relative w-14 group ${pageCounter == 3 ? 'opacity-50' : 'opacity-100'}`} onClick={incPage}>
                            <img src={arrowBottom} className='absolute left-3 top-1 drop-shadow-xl' alt="arrow bottom"/>
                            <img src={arrowTop} className='absolute left-0 top-0 group-active:top-1 group-active:-left-0.5 drop-shadow-md' alt="arrow top"/>
                        </button>
                    </div>

                </div>
            </div>

            {/* FOOTER */}
            <div className='absolute left-4 right-4 bottom-6 mt-20 flex flex-row place-content-between'>
                <img alt="img" src={winghausLogoTransparent} className='w-[53px]' />
                <PrimaryButton 
                    size="small" 
                    type="primary" 
                    className="!w-[164.95px]"
                    clickEvent={onSubmit}
                    >Next
                </PrimaryButton>
            </div>
        </div>
    </>)
}

function RenderJerseys({idx, selectJersey, currentJersey, direction, right, handleTouchStart,handleTouchMove }) {

    function jerseyTile(id) {
        return(
        <AnimatePresence>
            <motion.div 
                key={id}
                onClick={()=>{selectJersey(id + (4 * idx))}} className='relative active:scale-90 scale-100 transition-all duration-[30ms] color-img-wrapper'
                initial={{scale:0.5}}
                animate={{scale:1}}
            >
                <img
                    src={`/team_colours/${jerseyReference[id + (4 * idx)].jersey}`} className="h-[22.302dvh] mx-auto" />
                { currentJersey === id + (4 * idx) && <motion.img initial={{scale: 0.9}} animate={{scale: 1}} src={selectedJerseyIcon} className='absolute -top-4 w-[8.297dvh]' style={{ 'right' : `${right}px` }} alt="selected" />}                
            </motion.div>
        </AnimatePresence>
        )
    }

return(<>
        <motion.div
            key={idx}
            className='grid grid-cols-2 gap-y-8 h-[48.709dvh]'
            initial={{opacity: 0, x: 100 * direction }}
            animate={{opacity: 1, x: 0 }}
            exit={{opacity: 0, x: -100 * direction }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            { jerseyTile(0) }
            { jerseyTile(1) }
            { jerseyTile(2) }
            { jerseyTile(3) }
        </motion.div>
    </>)
}