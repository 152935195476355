/* 
 * Splash screen: function is simply to direct players to the sign up form
 * Note: Background image is placeholder as the intention is the interface will animate, while the background remains static between 'pages'
 */
import { motion, AnimatePresence } from "framer-motion";

import chickLogo from '../img/cnc_logo_lrg@3x.png';
import winghausLogoRed from '../img/winghaus-logo.svg';
import { PrimaryButton } from "./buttons";
import useDetectDVH from "../hooks/useDetectDVH";

export default function MainMenu({ handlePlay }) {
    useDetectDVH('.mainMenuBg')

    return(<>
        <div className='mainMenuBg absolute top-0 left-0 z-[999] w-full h-[100dvh] flex flex-col items-center overflow-scroll'>
            <motion.img
                initial={{scale: 0.7, opacity: 0}}
                animate={{scale: 1, opacity: 1, transition: {duration: 1, delay: 0}}}
                src={chickLogo}
                className='mt-6 w-[35vh]'
            />

            <div className='mt-[20vw] flex flex-col space-y-[4vw] text-center'>
                <motion.h4 initial={{opacity: 0, y:-50}} animate={{opacity: 1, y:0, transition: {delay: 1}}} className="text-[3vw]">Presented by</motion.h4>
                <motion.img initial={{opacity: 0, y:-50}} animate={{opacity: 1, y:0, transition: {delay: 1.5}}} src={winghausLogoRed} className='w-[30vw]' />
            </div>

            <motion.div initial={{opacity: 0, y:50}} animate={{opacity: 1, y:0, transition: {delay: 2.2}}} className='mt-20'>
                <PrimaryButton 
                    size="small" 
                    type="primary" 
                    className="!w-[164.95px]"
                    clickEvent={handlePlay}
                >Play</PrimaryButton>
            </motion.div>
        </div>
    </>)
}