import service from "./service";

export async function getLeaderboard({ size, dir }) {
  const response = await service.get('leaderboard-rank', { params: { size, asc_desc: dir } });
  return response.data
}

export async function getSelfPosition(id) {
  const response = await service.get('leaderboard-rank/'+id);
  return response.data
}