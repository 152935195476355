import { motion, AnimatePresence } from "framer-motion";
import CloseButton from "./button_close";
import { PrimaryButton } from "./buttons";

export default function SorryModal({visible, onClose, onBack}) {
  return (<AnimatePresence>
    {
      visible && (
        <motion.div 
        className="absolute inset-x-0 inset-y-0 bg-black bg-opacity-80 backdrop-blur-[7.5px] z-[1000] flex flex-col justify-center items-center"
        initial={{scale: 0}}
        animate={{scale:1}}
        exit={{ scale: 0 }}
      >
        <div 
          className="relative w-[40.99dvh] pt-[4dvh] pb-[10vw] px-4 rounded inviteModalBg"
        >
          <div className="flex justify-end">
            <CloseButton onClick={onClose}/>
          </div>
          <div className='my-[8.05dvh]'>
            <h2 className="font-jock text-white text-center text-[4.73dvh] leading-none">SORRY</h2>
            <div className='text-white text-center text-[2.369dvh] mx-auto w-[85%] tracking-[0.6px] leading-none font-jock mt-[2dvh]'>
              you've reached the maximum number of plays today.<br /> Come back tomorrow for another chance to win.
            </div>
          </div>
          <div className="mx-auto flex justify-center">
            <PrimaryButton 
              size="medium" 
              type="primary" 
              clickEvent={onBack}
            >BACK</PrimaryButton>
          </div>
        </div>
      </motion.div>
      )
    }
    </AnimatePresence>
  )
}