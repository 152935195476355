import React, { useEffect, useState } from 'react';
import { Howl, Howler } from 'howler';
import { crowdPath } from '../utils';
import { menuMusicPath } from '../utils';

function SoundPlayer({ soundPath, volume, setVolume }) {
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);

  useEffect(() => {
    const sound = new Howl({
      src: [soundPath],
      onplay: () => {
        setIsSoundPlaying(true);
      },
      onend: () => {
        setIsSoundPlaying(false);
        setVolume(0)
      },
    });

    const handlePlaySound = () => {
      if (!isSoundPlaying) {
        sound.play();
      }
    };

    if (volume > 0) {
      handlePlaySound();
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      if (volume === 0 && !isSoundPlaying) {
        sound.unload();
      }
      // Optionally, you can also call Howler.unload() to clear all sound objects
      // Howler.unload();
    };
  }, [soundPath, isSoundPlaying, volume]);

  return <></>;
}

export default SoundPlayer;

export function BackgroundMusic() {

  useEffect(() => {
    const sound = new Howl({
      src: [crowdPath],
      loop: true,
      onplay: () => {
        Howler.mute(false);
      },
      onend: () => {
        Howler.mute(true);
      },
    });

    sound.play()
    sound.fade(0, 1, 45000)

    // Clean up the event listener when the component is unmounted
    return () => {
      sound.unload();
      // Optionally, you can also call Howler.unload() to clear all sound objects
      // Howler.unload();
    };
  }, []);

  return <></>;
}

export function MenuMusic() {

  useEffect(() => {
    const sound = new Howl({
      src: [menuMusicPath],
      loop: true,
      onplay: () => {
        Howler.mute(false);
      },
      onend: () => {
        Howler.mute(true);
      },
    });

    sound.play()
    sound.fade(0, 1, 45000)

    // Clean up the event listener when the component is unmounted
    return () => {
      sound.unload();
      // Optionally, you can also call Howler.unload() to clear all sound objects
      // Howler.unload();
    };
  }, []);

  return <></>;
}