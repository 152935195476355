import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import logoImg from '../img/winghaus-full-logo.png'
import chickenImg from '../img/chicken.png'
import beerImg from '../img/beer 1.png'
import { PrimaryButton } from './buttons'
import CloseButton from './button_close'
import claimedImg from '../img/claimed.png';
import { claimReward } from '../api/prize';
import SorryModal from './sorry_modal';
import useDetectDVH from '../hooks/useDetectDVH';

function Modal({visible, onClose, onClaim}) {
  return <AnimatePresence>
    {
      visible && (
        <motion.div 
          className="absolute inset-x-0 inset-y-0 bg-black bg-opacity-80 backdrop-blur-[7.5px] z-[1000] flex flex-col justify-center items-center"
          initial={{scale: 0}}
          animate={{scale:1}}
          exit={{ scale: 0 }}
        >
          <div 
            className="relative w-[88.94vw] pt-[8vw] pb-[24vw] h-[115.68vw] px-4 rounded inviteModalBg"
          >
            <div className="flex justify-end ">
              <CloseButton onClick={onClose}/>
            </div>
            <div className='my-[20vw] text-white text-center text-[5.14vw]  mx-auto w-[95%] tracking-[0.6px] leading-none font-jock'>
              Are you sure you want to mark as claimed? You won't be able to claim your prize again.
            </div>
            <div className="mx-auto flex justify-center">
              <PrimaryButton 
                size="small" 
                type="primary" 
                clickEvent={onClaim}
                className="!w-[164.95px] mx-auto"
              >CLAIM</PrimaryButton>
            </div>
          </div>
        </motion.div>
      )
    }
  </AnimatePresence>
}

export default function Gameover({score, prize, toLeaderboard, alreadyPlayed, onBack}) {
  const [showModal, setShowModal] = useState(false)
  const [isClaimed, setIsClaimed] = useState(false)
  const [showSorryModal, setShowSorryModal] = useState(false)
  
  useDetectDVH()

  useEffect(() => {
    if (alreadyPlayed) {
      setShowSorryModal(true)
    }
  }, [alreadyPlayed])
  
  useEffect(() => {
    if (prize && prize.isClaimed) {
      setIsClaimed(true)
    }
  }, [prize])

  const claimPrize = async () => {
    if (prize.encryptedId) {
      await claimReward(prize.encryptedId)
      setIsClaimed(true)
      setShowModal(false)
    }
  }

  return (<>
    {!alreadyPlayed && (
      <div className={`grassBg absolute top-0 left-0 z-[999] w-full h-[100dvh] py-6 overflow-y-scroll`}>
      <div className='relative winningBg w-[89.2vw] pt-[15vw] h-auto pb-[10vw] text-center mx-auto'>
        <img src={logoImg} className="w-[13.95vw] h-[13.95vw] absolute top-0 -translate-y-1/4 left-1/2 transform -translate-x-1/2" />
        <h2 className="font-jock text-white text-[5.14vw]">YOUR SCORE</h2>
        <motion.div initial={{y: 50, opacity: 0}} animate={{y: 0, opacity: 1}} className="text-[#A6DBD3] font-jock text-[12.339vw] leading-none">{score || '000000'}</motion.div>
        <div className='font-jock text-[5.14vw] mt-[4.6vw] tracking-[0.6px] leading-none'>You’ve unlocked..</div>
        <div className="text-[10.28vw] leading-none tracking-[0.9px] font-jock mt-[3vw]">{
          prize ? prize.name.split(' ').map((w, i) => <span key={i}>{w} <br /></span>):
          <>Free<br/>Large<br/>Wings...</>
        }</div>
        <img src={prize && prize.name.toLowerCase().includes('beer') ? beerImg : chickenImg} className='w-[39.58vw] mx-auto' />
        <div className="font-jock w-[70%] mx-auto mt-[9vw] text-[5.14vw] leading-none tracking-[0.6px]">Present this to bar staff to claim.</div>
      </div>
      {
        isClaimed ? <motion.img 
          src={claimedImg} 
          className='h-[68px] w-auto mx-auto mt-[2.36vw]'
          initial={{scale: 0}}
          animate={{scale:1}}
        /> : <motion.div 
        initial={{scale: 0}}
        animate={{scale:1, transition: { delay: 0.1 }}}
        exit={{ scale: 0 }}
      ><PrimaryButton 
          size="small" 
          type="primary" 
          className="mt-[4.7vw]  !w-[164.95px]  mx-auto"
          clickEvent={() => setShowModal(true)}
        >
          CLAIM
        </PrimaryButton> </motion.div>
      }

      <motion.div 
        initial={{scale: 0}}
        animate={{scale:1, transition: { delay: 0.1 }}}
      >
        <PrimaryButton 
          size="long" 
          className="!w-[264.33px] !mt-[6vw]"
          type={isClaimed ? 'primary' : undefined}
          clickEvent={toLeaderboard}
        >
          LEADERBOARD
        </PrimaryButton>
      </motion.div>
    </div>
    )}
    {
      showModal && (
        <Modal
          visible={showModal}
          onClaim={claimPrize}
          onClose={() => setShowModal(false)}
        />
      )
    }
    {
      showSorryModal && (
        <SorryModal
          visible={showSorryModal}
          onBack={onBack}
          onClose={onBack}
        />
      )
    }
  </>)
}


