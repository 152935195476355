import { motion, AnimatePresence } from "framer-motion";
import CloseButton from './button_close'

export default function Terms({ visible, onClose }) {
  return <AnimatePresence>
    {
      visible && (
        <motion.div 
          className={`absolute inset-x-0 inset-y-0 bg-black bg-opacity-50 backdrop-blur-[7.5px] z-[1000] flex flex-col justify-center items-center`}
          initial={{scale: 0}}
          animate={{scale:1}}
          exit={{ scale: 0 }}
          >
          <div className='winningBg w-[89.47vw] relative pt-[10vw] h-[90%]'>
            <div className="flex justify-end pr-[3.5vw]">
              <CloseButton onClick={onClose}/>
            </div>
            <h2 className="text-[7vw] tracking-[0.9px] leading-none text-white w-[80%] mx-auto font-jock">Terms &<br /> Conditions</h2>
            <p className="mx-auto w-[80%] mt-[3vh] text-[3.7vw]">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <p className="mx-auto w-[80%] mt-[3vh] text-[3.7vw]">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p className="mx-auto w-[80%] mt-[3vh] text-[3.7vw]">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p className="mx-auto w-[80%] mt-[3vh] text-[3.7vw]">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p className="mx-auto w-[80%] mt-[3vh] text-[3.7vw]">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          </div>
        </motion.div>
      )
    }
  </AnimatePresence>
  


}